ul {

  &.cols-4 {
    column-count: 4;

    @media (max-width: $screen-sm-max) {
      column-count: 2;
    }
  }

  &.no-li-style {
    list-style-type: none;
  }
}