.modal-text {
  margin: 10px 0;
}

@media (max-width: $screen-sm-min) {
  .modal-text {
    max-height: 500px;
    overflow-y: auto;
  }
}

.modal-body {
  // modal text body in mobile should be centered
  @media (max-width: $screen-sm-min) {
    text-align: center;
  }

  .sub-header-md {
    color: $marengo-grey;
    padding-bottom: $spacing-large;
    font-weight: bold;
    font-size: $label-value-lg;
    line-height: 27px;
    @media (max-width: $screen-sm-min) {
      font-size: $label-sub-header;
    }
  }

  .form-group {
    margin-bottom: $spacing-tiny;
  }

  .field-category {
    .section-header {
      margin-bottom: $spacing-default;
      font-size: $label-value-lg;
      color: $ocean-blue;
      font-weight: bold;
    }
  }
}

.info-picture-modal {
  text-align: center;

  &__text {
    font-size: 14px;
    line-height: 25px;
    text-align: left;
    @media (max-width: $screen-x-sm-min) {
      text-align: center;
    }
  }
}
