.flow-success {
  text-align: center !important;

  &__image {
    margin-bottom: $spacing-medium;
  }

  &__action-row {
    margin-top: $spacing-medium;
  }
}
