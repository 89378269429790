.alert {

  .message {
    margin-bottom: 0;
  }

  &.message-ocean-blue {

    .message {
      color: $ocean-blue;
    }
  }

  &.message-btm-margin {

    .message {
      margin-bottom: $spacing-tiny;
    }
  }

  &.font-regular {
    font-weight: 400;
  }

  &__bkgrnd-blue {
    background-color: $ocean-blue;

    p {
      color: white;
    }
  }
}