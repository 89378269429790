.input-skeleton-group {
  overflow: hidden;
}

.input-skeleton {
  float: left;
  width: 48%;
  opacity: 0.5;

  @media (max-width: $screen-x-sm-min) {
    width: 100%;
    float: none;
  }

  &__label {
    height: $spacing-small;
    width: 25%;
    position: relative;
    overflow: hidden;
    background-color: $sky-grey;
    margin-bottom: $spacing-tiny;
    border-radius: $border-radius;

    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background: linear-gradient(90deg,
          transparent,
          rgba(255, 255, 255, 0.55),
          transparent);
      animation: loading 1.5s infinite;
    }
  }

  &__input {
    border-radius: $border-radius;
    height: $spacing-small;
    width: 90%;
    position: relative;
    overflow: hidden;
    background-color: $sky-grey;
    margin-bottom: $spacing-medium;

    &::after {
      display: block;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background: linear-gradient(90deg,
          transparent,
          rgba(255, 255, 255, 0.55),
          transparent);
      animation: loading 1.5s infinite;
    }
  }
}

.input-skeleton-group.vertical {
  display: flex;
  flex-direction: column;

  .input-skeleton {
    float: none;
    width: 100%;

    &__input {
      width: 100%;
    }

    &:last-of-type {

      .input-skeleton__input {
        margin-bottom: 0;
      }
    }
  }
}

.input-skeleton-group.hide-labels {

  .input-skeleton {
    &__label {
      display: none;
    }
  }
}

@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}