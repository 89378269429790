/* muli-regular - latin */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/muli-v12-latin-regular.eot"); /* IE9 Compat Modes */
  src: local("Muli Regular"), local("Muli-Regular"),
    url("../fonts/muli-v12-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/muli-v12-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/muli-v12-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("../fonts/muli-v12-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/muli-v12-latin-regular.svg#Muli")
      format("svg"); /* Legacy iOS */
}

/* muli bold - def for facecheck since we cant set weight */
@font-face {
  font-family: "Muli Bold";
  font-style: normal;
  src: url("../fonts/Muli-Bold.eot"); /* IE9 Compat Modes */
  src: local("Muli Bold"), local("Muli-Bold"),
    url("../fonts/Muli-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/Muli-Bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/Muli-Bold.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/Muli-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/Muli-Bold.svg#Muli") format("svg"); /* Legacy iOS */
}

/* muli bold */
@font-face {
  font-family: "Muli";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/Muli-Bold.eot"); /* IE9 Compat Modes */
  src: local("Muli Bold"), local("Muli-Bold"),
    url("../fonts/Muli-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("../fonts/Muli-Bold.woff2") format("woff2"),
    /* Super Modern Browsers */ url("../fonts/Muli-Bold.woff") format("woff"),
    /* Modern Browsers */ url("../fonts/Muli-Bold.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("../fonts/Muli-Bold.svg#Muli") format("svg"); /* Legacy iOS */
}

.text-green {
  color: darken($green, 10%);
}

.text-blue {
  color: $blue;
}

.text-grey {
  color: $silver;
}

.highlight-blue {
  font-weight: bolder;
  font-size: 1.2em;
  color: $light-blue;
}
