.lock-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $screen-sm-min) {
    flex-direction: column;
  }

  .card-side {
    text-align: center;
    flex: 4;

    @media (max-width: $screen-sm-min) {
      margin-bottom: $spacing-medium;
    }

    figure {
      position: relative;

      img {
        max-width: 300px;
        padding: $spacing-small;
      }
    }

    .icon-wrapper {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .faq-side {
    flex: 5;

    summary {

      &>div {
        p {
          margin-right: $spacing-small;
        }
      }
    }
  }
}