.page-alert {
  @media (max-width: $screen-sm-min) {
    margin: $spacing-tiny 0;
  }

  @media (max-width: $screen-x-sm-min) {
    position: fixed;
    top: -100%;
    z-index: 1000;
    width: 100%;
    margin: 0;
    transition: all 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }

  &-mobile {
    top: 0;
    transition: all 0.3s;
  }

  &-static {
    position: static;
    margin-bottom: $spacing-tiny;
  }
}

.page-success {
  border-color: $alert-green;
  background-color: $alert-green-bg;
  color: $ocean-blue;
}
