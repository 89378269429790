.v2 {
  .currency-field-container {
    display: flex;

    & > .currency-input-section {
      display: inline-block;
    }

    & > .currency-field {
      flex-grow: 1;
      margin-right: $spacing-tiny;
    }

    & > .right-arrow {
      margin-right: $spacing-tiny;
    }

    & > .target-currency-input {
      width: 25%;
      min-width: 90px;
    }

    & input.currency-input {
      text-align: right;
    }

    & .right-arrow {
      font-size: 32px;
      display: flex;
      text-align: center;
      justify-content: center;
      align-self: center;
      width: 5%;
    }
  }
  .currency-converter-input {
    .form-group {
      margin-bottom: 0px;
    }
  }
}
