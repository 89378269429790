.account-details {

  //TODO: Get with designer as to why these are different than styleguide
  h2,
  h3 {
    color: $navy-blue;
  }

  label {
    color: $shark-grey;
  }

  .account-details-display {
    @media (min-width: $screen-md-min) {
      border-left: 1px solid $beluga-white;
    }
  }

  .profile-header {
    margin: 20px 0 40px;
    display: flex;
  }

  .image-wrapper {
    position: relative;
    width: 160px;

    .edit-image {
      position: absolute;
      left: 40px;
      bottom: -15px;
      border-radius: 14px;
      background-color: $white;
      box-shadow: 0 10px 14px rgba(51, 156, 242, 0.2);
      padding: 3px 10px 0 10px;
    }
  }

  // .name-wrapper {}

  @media (min-width: $screen-sm-min) {
    .image-wrapper {
      display: inline-block;
    }

    .name-wrapper {
      display: inline-block;
      margin-left: 30px;
      align-self: center;
      margin-bottom: 10px;
    }
  }

  div[class^="col-"] {
    margin-bottom: 20px;
  }

  .label-wrapper {
    display: flex;
    align-items: center;
  }

  .h3-label {
    margin-bottom: 20px;
  }

  //TODO: Look into if this is a standard pattern
  .section {
    margin-bottom: 10px;
  }
}

.user-profile-modal {
  .modal-body {
    text-align: left;
  }
}

.ol-markers-large {
  li::marker {
    font-size: 18px;
    color: $ocean-blue;
    font-weight: bold;
  }
}

.ol-markers-abyss-blue {
  li::marker {
    color: $abyss-blue;
  }
}

.info-box-outline {
  max-width: 50%;
  border: 1px solid $sky-grey;
  border-radius: $border-radius;
  padding: $spacing-tiny;

  @media (max-width: $screen-sm-min) {
    max-width: 100%;
  }
}

.webview-styling {
  padding: $spacing-medium;
}