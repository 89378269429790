form.authentication-app-inputs {
  input {
    width: 40px;
    font-size: 24px;
    border: 0;
    border-bottom: 2px solid $shark-grey;
    margin-right: 5px;
    background-color: transparent;
    text-align: center;
    appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      appearance: none;
    }

    &:active,
    &:focus,
    &:focus-visible {
      border: 0;
      outline: 0;
      border-bottom: 2px solid $brightwell-blue;
    }

    &:nth-of-type(3) {
      margin-right: 15px;
    }
  }

  &.full-width {
    display: flex;

    input {
      flex: 1
    }
  }
}