@import "../common/variables";

.wizard-container {
  padding-bottom: 100px;

  @media (max-width: $screen-sm-min) {
    height: 100%;
  }

  .subheader {
    color: $marengo-grey;
  }

  .wizard-child-container {
    button.nav-next {
      font-size: 18px;
    }

    border-radius: $border-radius;

    .form-group {
      label {
        color: $shark-grey;
      }
    }
    .mt-row {
      margin-right: 0px;
      margin-left: 0px;
    }
  }
}
