.v2.totp-flow {

  h1 {
    margin-bottom: 15px !important;
  }

  .action-row__backward {
    float: left;
  }
}

.v2.how-it-works__screen {
  padding: 0;

  h1 {
    padding-left: 0;
  }
}