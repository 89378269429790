//Redesign 3 color palette: 
$gray-100: #eceef2;
$gray-200: #D6DAE1;
$gray-500: #69788E;
$gray-600: #576479;
$gray-700: #454F5F;

$primary-500: #338BF2;
$primary-600: #2874E8;
$primary-700: #205FD5;

//Redesign 2 color palette:
$navy-blue: #004780;
$ice-blue: #1c74d9;
$brightwell-blue: #338bf2;
$shark-grey: #8994a6;
$error-red: #ff0033;
$warning-red: #cc1f42;
$sky-grey: #c5d0de;
$iceberg-white: #f6f7f8;
$beluga-white: #edeff2;
$brightwell-green: #42cb91;
$abyss-blue: #111e30;
$marengo-grey: #576479;
$interactive-blue: #1c74d9;
$ocean-blue: #004780;
$alert-green: #30996d;
$alert-green-bg: #d5ebe2;

//Redesign color palette:
$emerald: #33cc66;
$cerulean-blue: #3366cc;
$danube: #4882c4;
$indigo: #7d92d6;
$link-water: #b9c7eb;
$torch-red: #ff0033;
$amber: #ffc000;
$mine-shaft: #333333;
$silver: #cccccc;
$white: #ffffff;
$off-white: #edeff2;

//For usage:
$blue: $cerulean-blue;
$light-blue: #338ae2; // #6cb2c0;
$red: $torch-red;
$green: $emerald;
$yellow: $amber;
$dropShadow: rgba(51, 156, 242, 0.2);

//legacy
$alert-text: #ff0033;

//screen sizes (follows bootstrap 3)
$screen-xxs-min: 350px;
$screen-xs-min: 360px;
$screen-x-sm-min: 440px;
$screen-xs-max: 767px;
$screen-sm-min: 768px;
$screen-sm-max: 991px;
$screen-md-min: 992px;
$screen-md-max: 1169px;
$screen-lg-min: 1170px;

// Border Radius
$border-radius: 5px;

// Labels and such
$label-text-xs: 9px;
$label-text-sm: 12px;
$label-text-md: 14px;
$label-value-sm: 12px;
$label-value-md: 14px;
$label-value-lg: 18px;
$label-sub-header: 16px;
$label-header-sm: 20px;
$label-header: 24px;
// since the bold font wasnt included until we added
// facecheck, we need to remove the bold weight from
// buttons and labels, in reality they weren't bolder
// they just use a darker color which gives the impression
$label-and-button-weight: 400;

// Spacing
$spacing-large: 50px;
$spacing-medium: 30px;
$spacing-small: 15px;
$spacing-tiny: 10px;
$spacing-xs: 5px;
$spacing-default: 15px;
$padding-default: 15px;
$padding-btn-small: 10px;
$padding-btn: 15px;

// Font sizes
$font-size-xs: 10px;
$font-size-sm: 12px;
$font-size-default: 14px;
$font-size-lg: 16px;
$font-size-xl: 18px;

// Forms
$label-font-size: 15px;
$checkbox-spacing: 4px;