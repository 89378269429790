@mixin support-card {
  border: 2px solid $sky-grey;
  min-height: 60px;
  width: 100%;
  background-color: $white;
  color: $blue;
  position: relative;
  text-align: left;
  overflow: hidden;
  margin-bottom: 0;
  border-radius: 6px;
  filter: drop-shadow(-2px 5px 3px rgba(0, 71, 128, 0.15));
}

.support {

  .page-title {
    margin: $spacing-medium 0;
    margin-top: $spacing-default;

    @media (max-width: $screen-sm-max) {
      font-size: 28px;
      margin-top: $spacing-medium;
      margin-bottom: $spacing-default;
    }
  }

  .page-subtitle {
    font-size: $font-size-xl;
  }

  ul {
    li {
      list-style-type: none;
      position: relative;
    }
  }

  .splitter-grey {
    background-color: $beluga-white;
    padding: 7px;
    font-size: $font-size-sm;
    font-weight: bold;
  }

  .center-text {
    flex-direction: column;
    vertical-align: top;
    text-align: center;

    span {
      text-align: center;
      font-weight: bold;
    }
  }

  .actions-container {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
    color: $interactive-blue;

    @media (max-width: $screen-sm-max) {
      margin-top: 0;
    }

    a {
      color: $interactive-blue;
    }

    .support-banner {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: $spacing-large 0;
      background-color: $beluga-white;
      border-radius: $border-radius;
    }
  }

  .action-image-container {
    border-radius: 50px;
    background-color: $brightwell-blue;
    color: $white;
    width: 95px;
    height: 95px;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action-image {
    width: 40px;
  }

  .message-history-container {
    margin-bottom: $spacing-large;

    ul {
      margin-bottom: 0;
    }
  }

  %status-icon {
    height: 15px;
    width: 15px;
    border-radius: 50%;
  }

  .profile-icon {
    height: 52px;
    border-radius: 50px;
    display: flex;
  }

  .support-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    margin-top: $spacing-default;

    @media (max-width: $screen-sm-max) {
      margin-top: $spacing-medium;
    }
  }

  .support-container-modal {
    bottom: initial;
    padding-bottom: 80px;
  }

  .conversation-container {
    @media (max-width: $screen-sm-max) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .messages-container {
    flex-grow: 1;
    border: 1px solid $beluga-white;
    border-radius: $border-radius;
    margin: 30px 0;

    @media (max-width: $screen-sm-max) {
      border: 0;
      margin-bottom: 65px;
    }
  }

  .attachment-container {
    margin-top: 10px;
    max-width: 100px;

    img {
      max-width: 100px;
      max-height: 50px;
      border-radius: 10px;
    }
  }

  .security-message {
    font-size: 0.8em;
    margin-top: 5px;
  }

  .message-bwp {
    .profile-icon-container {
      order: 1;
      width: 40px;
      height: 40px;
      background-color: $brightwell-blue;
      border-radius: 50%;
      padding: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 20px;
      }
    }

    .message-text {
      order: 2;
    }
  }

  .message-own {
    .profile-icon-container {
      order: 2;
    }

    .message-text {
      order: 1;
    }
  }

  .align-right {
    margin-right: auto;
    text-align: right;
  }

  .align-left {
    margin-left: auto;
    text-align: left;
  }

  [data-initials]:before {
    content: attr(data-initials);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    background: $beluga-white;
    color: $brightwell-blue;
    vertical-align: middle;
    border: 1px solid $sky-grey;
  }

  .conversation-list {
    padding: 0;
  }

  .conversation-item {
    border: 1px solid $beluga-white;
    border-bottom: none;
    padding: $spacing-small;
    cursor: pointer;

    &:first-of-type {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;

    }

    &:last-of-type {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      border-bottom: 1px solid $beluga-white;
    }

    &.closed {

      .conversation-item__info {

        p {
          color: $marengo-grey;
        }

        .conversation-data__status {

          .dot {
            background-color: $shark-grey;
          }

          p {
            color: $shark-grey;
          }
        }
      }

      .conversation-item__date {
        color: $marengo-grey;
      }
    }

    &__info {

      img {
        width: 24px;
      }

      .conversation-data__status {

        .dot {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: $brightwell-blue;
          margin-right: 5px;
        }

        p {
          margin-bottom: 0;
          font-size: 12px;
          color: $marengo-grey;
        }
      }

      .ticket {
        font-size: 12px;
      }

    }

    &__date {
      color: $abyss-blue;

      img {
        width: 18px;
      }
    }



    label {
      cursor: pointer;
      font-size: $font-size-lg;
      margin-bottom: $spacing-tiny / 2;
    }

    h5 {
      margin: 0;
    }

    .conversation-date {
      position: relative;
      padding-left: 15px;

      @media (min-width: $screen-sm-min) {
        padding-left: 20px;
      }
    }

    .has-unread-messages {
      position: absolute;
      top: 50%;
      left: 0;
      margin-top: -5px;
      display: inline-block;
      background-color: $error-red;
      width: 10px;
      height: 10px;
      border-radius: 10px;

      @media (min-width: $screen-sm-min) {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        margin-top: -7.5px;
      }
    }

    .conversation-status {
      display: flex;
      margin-bottom: $spacing-tiny / 2;

      .status-open,
      .status-submitted,
      .status-assigned {
        @extend %status-icon;
        background-color: $green;
      }

      .status-closed {
        @extend %status-icon;
        background-color: $blue;
      }

      span {
        font-weight: bold;
      }
    }

    .greater-than {
      font-size: 2em;
    }
  }

  .conversation-title {

    @media (max-width: $screen-sm-max) {
      // padding: 0 15px;
      flex-direction: column;
    }

    a {
      margin-right: auto;
      text-decoration: underline;

      @media (max-width: $screen-sm-max) {
        margin-bottom: 15px;
      }
    }

    p {
      margin-bottom: 0;
      margin-right: auto;
      font-weight: 700;
    }
  }

  .message-list {
    padding: 0 $spacing-medium;

    @media (max-width: $screen-sm-max) {
      // padding: 0 15px;
      padding: 0;
    }

    .date-header {
      text-align: center;
      color: $silver;
      margin: 10px 0;
    }
  }

  .message {
    margin-bottom: 30px;

    .time {
      width: 100%;
      color: $silver;
    }

    .message-container {
      width: 100%;
      display: flex;
      align-items: flex-start;
    }

    .message-text {
      padding: 15px;
      margin: 0 10px;
      border-radius: 8px;
      background-color: $beluga-white;
      color: $marengo-grey;
      line-height: 21px;
      flex: 0 1 auto;
      max-width: 70%;
      white-space: pre-line;
    }

    &.message-own {
      .time {
        text-align: right;
        margin-top: 3px;

        span {
          margin-right: 50px;
          color: $shark-grey;
        }
      }

      .message-container {
        justify-content: flex-end;
      }
    }

    &.message-bwp {
      .time {
        text-align: left;
        margin-top: 3px;

        span {
          margin-left: 50px;
          color: $shark-grey;
        }
      }
    }
  }

  #conversation-form {
    width: 100%;
    background-color: $beluga-white;
    padding: 15px 30px;

    @media (max-width: $screen-sm-max) {
      padding: 15px;
      position: fixed;
      bottom: 0;
      left: 0;
      border-top: 1px solid $sky-grey;
    }

    .btn {
      margin-top: 30px;
    }

    .form-group {
      margin-bottom: 0;
    }
  }

  .file-upload {
    cursor: pointer;

    .file-upload-label {
      margin-bottom: 0;
      cursor: pointer;

      img {
        pointer-events: none;
        cursor: pointer;
      }

      input {
        visibility: hidden;
        width: 0;
        height: 0;
        position: absolute;
      }
    }
  }

  .support-message-input {
    padding-right: 0;
    flex: 1;

    input {
      border: 1px solid white;
      background-color: white;
      outline: 0;
      box-shadow: none;
      color: $shark-grey;

      &::placeholder {
        color: $shark-grey;
        font-style: italic;
      }
    }
  }

  .send-message {
    cursor: pointer;

    .btn-send {
      color: $white;
      padding: 5px 0px;
    }
  }

  .phone-list {
    padding: 0;
    margin: 20px;
  }

  .support-phone {
    margin: 15px 0;
  }

  //Categories:
  &.category {

    //background-color: $silver;
    h2,
    p {
      //margin-left: 45px;
    }
  }

  .category-selection {
    text-align: center;

    @media (min-width: $screen-md-min) {
      text-align: left;
      max-width: 360px;
    }
  }

  .grid-center {
    display: grid;
    align-items: center;
  }

  .category-title {
    font-size: $font-size-lg;
    font-weight: bold;
  }

  .category-root {
    padding: $spacing-small;
    display: flex;
    flex-direction: row;
    align-items: center;
    outline: none;

    .card-image {
      margin-right: $spacing-tiny;
    }

    .drop-icon {
      margin-left: auto;
      transition: transform 0.35s ease-in-out;

      @media (min-width: $screen-sm-min) {
        transform: rotate(0deg);
      }
    }
  }

  .card-sm {

    //border: 1px solid blue;
    //float: left;
    @media (max-width: $screen-sm-max) {
      @include support-card;
    }
  }

  .card-md {
    width: 100%;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid $sky-grey;

    @media (min-width: $screen-sm-min) {
      @include support-card;
      margin-bottom: $spacing-small;
    }
  }

  .category-card {
    position: relative;

    &:hover {
      z-index: 1000;
    }

    // For mobile
    &.expanded {
      @media (max-width: $screen-md-max) {
        .sub-container {
          display: block;
        }
      }
    }

    &:hover,
    &.selected {
      .category-root {
        @media (min-width: $screen-sm-min) {
          border: 2px solid $interactive-blue;
        }
      }

      &.no-childs {
        .category-root {
          @media (max-width: $screen-md-max) {
            background-color: $sky-grey;
          }
        }
      }

      .sub-container {
        display: block;
      }

      .drop-icon {
        transform: rotate(180deg);

        @media (min-width: $screen-sm-min) {
          transform: rotate(-90deg);
        }
      }
    }

    .sub-container {
      background-color: $white;
      display: none;

      @media (min-width: $screen-sm-min) {
        padding-left: $spacing-tiny;
        position: absolute;
        top: 0;
        left: auto;
        left: 100%;
        width: 360px;
      }

      .card {
        padding: 0;
      }

      button {
        width: 100%;
        padding: $spacing-large/2 $spacing-large;
        text-align: left;
        background-color: $white;
        border: none;
        outline: none;

        @media (min-width: $screen-sm-min) {
          padding: $spacing-default;
        }

        &:hover,
        &.selected {
          background-color: $interactive-blue;
          color: $white;
        }
      }
    }
  }
}

/** Support css to match support styling. Can be removed once we update to MT styling */
.support-modal-sub-header {
  color: $ocean-blue;
  font-size: $font-size-default;
  line-height: 25px;
  padding-bottom: $spacing-default;
}

.support-modal-label {
  font-weight: bold;
  color: $shark-grey;
  font-size: $font-size-default;
  line-height: 25px;
  padding-top: $spacing-tiny;
}

.phone-modal {
  .modal-text {
    overflow: hidden;
    margin: 0 0 $spacing-default 0;
  }

  // button is a link styled as button. adjust width
  .phone-btn-block {
    width: 90%;

    @media (max-width: $screen-xs-min) {
      width: 50%;
    }
  }
}