@import "../common/variables";

.currency-converter {
  @media (max-width: $screen-sm-min) {
    height: 100%;
  }
  .disabled {
    opacity: 0.5;
  }

  .input-group-addon {
    font-weight: bold;
    font-size: 12px;
  }

  .has-error .input-group-addon {
    color: $marengo-grey;
    background-color: inherit;
    border-color: inherit;
  }

  .has-success .input-group-addon {
    color: $marengo-grey;
    background-color: inherit;
    border-color: inherit;
  }

  .rate-card {
    border: 1.5px solid $sky-grey;
    border-radius: 5px;
    padding: 15px 25px 15px 25px;
    background-color: $white;
    margin-bottom: $spacing-small;

    .provider-logo {
      width: 100%;
      max-width: 300px;
    }

    .logo-desktop {
      width: 75%;
      padding-top: $spacing-small;
    }

    .exchange-rate {
      margin-top: $spacing-default;

      .rate-label {
        color: $shark-grey;
      }

      .rate-description {
        color: $ocean-blue;
      }
    }

    .fields-row {
      margin-top: $spacing-default;
    }
  }

  .disclaimer {
    margin-bottom: 5px;
    color: $shark-grey;
    font-size: $font-size-xs;
  }
  .calculate-button {
    padding-right: 20px;
    padding-top: $spacing-tiny;
    float: right;
    display: none;
  }
  .my-card-button-container {
    font-weight: bold;
    padding: $spacing-tiny 0;
  }

  &.has-summary {
    @media (min-width: $screen-sm-min) {
      .action-row-wrapper {
        display: none;
      }
      .calculate-button {
        display: block;
      }
    }

    @media (max-width: $screen-sm-min) {
      .action-row__backward {
        display: none;
      }
      .my-card-button-container {
        text-align: center;
      }
    }
  }
}
