.BWIcon {
  font-family: "BWIconFont" !important;

  &.Alert:before {
    content: "\e900";
  }

  &.Arrow-Right:before {
    content: "\e901";
  }

  &.Plus:before {
    content: "\e902";
  }

  &.Checkmark:before {
    content: "\e903";
  }

  &.Expand:before {
    content: "\e904";
  }

  &.Left-Arrow:before {
    content: "\e905";
  }

  &.Chevron-Down:before {
    content: "\e906";
  }

  &.Chevron-Right:before {
    content: "\e907";
  }

  &.Close-Small:before {
    content: "\e908";
  }

  &.Close-Large:before {
    content: "\e909";
  }

  &.Eye:before {
    content: "\e90A";
  }

  &.Hamburger:before {
    content: "\e90B";
  }

  &.Search:before {
    content: "\e90C";
  }

  &_type {
    &_Alert {
      color: $torch-red  !important;
    }

    &_Success {
      color: $brightwell-green  !important;
    }
  }
}

.custom-icon-font {

  &.checkmark-green-bg {
    span {
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: $alert-green;
      color: $alert-green-bg  !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 10px;
      padding: 3px;
    }

  }
}