footer {
  font-size: $font-size-sm;
  font-weight: normal;
  padding: 35px;
  margin-top: auto;
  flex-shrink: 0;
  background-color: $beluga-white;

  .page-items {
    color: $brightwell-blue;
  }
}