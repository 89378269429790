.recipient-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: $border-radius;
  margin-bottom: $spacing-small;
  padding: $spacing-tiny;
  border: 1px solid $beluga-white;

  &:first-of-type {
    margin-top: $spacing-small;
  }

  &:last-of-type {
    margin-bottom: none;
  }
}