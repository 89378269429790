.btn.btn-enrollment {
  &:hover {
    color: white !important;
  }
}

.enrollment-wrapper {
  .btn-default {
    box-shadow: 0px 10px 14px rgba(51, 156, 242, 0.2);
  }
}
