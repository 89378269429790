$headerPadding: 5px 15px;
$borderWidth: 1.5px;
$border: $borderWidth solid $sky-grey;
$borderRadius: 5px;

.card {
  border: $border;
  border-radius: $borderRadius;
  background-color: $white;

  .card-header {
    border-radius: ($borderRadius - $borderWidth) ($borderRadius - $borderWidth) 0 0;
    padding: $headerPadding;
    background-color: $beluga-white;
    border-bottom: $border;
    color: $ocean-blue;
    font-weight: bold;
    font-size: 16px;
  }

  .card-body {
    padding: $spacing-default;

    .flex-row {

      &>div {
        flex: 1;
        width: 0;
      }

      .value {
        word-wrap: break-word;
      }
    }
  }
}