.navbar-brand {
  height: 60px;
  font-size: 12px;
  padding: $spacing-small;

  @media (max-width: $screen-sm-min) {
    font-size: 10px;
    padding: $spacing-small 0;
  }
}

nav,
nav.navbar,
.navbar,
.navbar-static-top,
.navbar-default {
  background-color: $brightwell-blue;
  flex-grow: 0;
}

@media (max-width: 1000px) {

  nav,
  nav.navbar,
  .navbar,
  .navbar-static-top,
  .navbar-default {
    background-color: $brightwell-blue;
  }
}

nav {
  flex: 1 0 auto;
}

.navbar {
  min-height: 60px;

  #menu {
    margin-top: 15px;
  }

  @media (min-width: $screen-md-min) {
    #menu {
      display: flex;
      align-items: center;
      margin-bottom: 7px;
    }
  }

  @media (max-width: $screen-sm-min) {
    #account-support {
      margin-top: 20px;
      margin-left: 10px;
    }
  }

  nav ul li {
    font-size: 12px;
    font-weight: 600;
    list-style: none;
  }

  nav ul li a {
    text-decoration: none;
    color: $beluga-white;
    line-height: 1.5em;
  }

  nav ul li a:hover,
  nav ul li a:active {
    color: $shark-grey;
  }

  #logo {
    border: none;
    height: 65px;
    margin: 0 0 0 -12px;
  }

  .menu-item {
    white-space: nowrap;
  }

  ul#menu li.menu-state-default a:hover {
    color: $white;
  }

  ul#menu li.menu-state-highlight a {
    color: $white;
  }

  ul#menu li.menu-state-highlight a:hover {
    color: $white;
  }

  ul.subMenu {
    display: none;
    position: absolute;
    font-size: 100%;
    font-family: inherit;
    margin: 0;
    padding: 0;
    text-align: right;
    float: left;
  }

  ul.subMenu li {
    padding-top: 7px;
  }

  #menu a:active,
  #menu a:hover {
    color: $beluga-white;
  }

  #menu a:link,
  #menu a:visited {
    color: $beluga-white;
  }


  .badge-wrap {

    a {
      font-size: 12px;
      font-weight: 600;
      display: inline-block;
      min-height: 44px;
      padding: 10px;

      &.active {
        text-decoration: underline;
      }
    }
  }

  .notification-badge {
    width: 8px;
    height: 8px;
    background-color: $alert-text;
    /*margin-top: 0;*/
    border-radius: 50%;
    /*right: -5px;*/
    position: absolute;
  }

  .logOut {
    min-width: 116px;
  }

  #account-settings {
    margin-top: -15px;
  }

  .logo-wrapper {
    height: 100%;

    img {
      max-height: 100%;
      display: block;
    }
  }
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: $white;
}

.navbar-default .navbar-toggle {
  border-color: transparent;
  margin-right: $spacing-small;

  @media (max-width: $screen-sm-max) {
    margin-right: 0;
  }
}

#AccountSettings {
  text-transform: none;
}

.navbar-header.focused {
  float: none !important;

  #account-support {
    margin-top: 20px;
  }
}

/* CHANGING BREAKPOINT OF BOOTSTRAP 3 NAVIGATION BAR*/
@media (max-width: 1000px) {
  #account-support {
    margin-top: 20px;
  }

  .navbar-header {
    float: none;
  }

  .navbar-toggle {
    display: block;
    height: 40px;
    margin-top: 10px;
  }

  .navbar-collapse {
    box-shadow: initial;
    border-top: 0;
  }

  .navbar-collapse.collapse {
    display: none !important;
  }

  .navbar-collapse.collapse.in {
    display: block !important;
  }

  .navbar-nav {
    float: none !important;
    margin: 7.5px -15px;
  }

  .navbar-nav>li {
    float: none;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .navbar-form {
    padding: 10px 15px;
    margin-top: 8px;
    margin-right: -15px;
    margin-bottom: 8px;
    margin-left: -15px;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
      0 1px 0 rgba(255, 255, 255, 0.1);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1),
      0 1px 0 rgba(255, 255, 255, 0.1);
    width: auto;
    float: none !important;
  }

  .navbar-collapse.collapse,
  .navbar-collapse.collapse.in,
  .navbar-collapse.navbar-collapsing,
  .navbar-collapse.collapsing {
    li {
      padding: 10px 0;

      &:first-of-type {
        border: 0;
      }
    }

    .logOut {
      margin-top: 15px;
    }
  }
}