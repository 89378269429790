.left-nav {
  .header {
    border-bottom: 1px solid $beluga-white;
  }

  .nav-list {
    li {
      padding: 10px 0;
    }
  }
}
