.login {
  $login-form-label-size: 14px;

  @media (max-width: $screen-sm-max) {
    margin-top: $spacing-default;
  }

  h1 {
    padding-left: 0;
    color: $shark-grey;
  }

  .username {
    width: 24px;
    height: 24px;
  }

  .form-group {

    label,
    a {
      font-size: $login-form-label-size;
    }

    .row-link {
      padding-top: $checkbox-spacing;
    }
  }

  .username-label {
    font-size: $label-sub-header;
  }

  .remember-me-checkbox label {
    line-height: normal;
    color: $shark-grey;
    font-weight: 500;
  }
}