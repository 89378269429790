.transfers-list {
  border: 1px solid $beluga-white;
  border-radius: 10px;
}

.transfer-card {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 1fr;
  gap: $spacing-small;
  border-bottom: 0;
  padding: $spacing-small;

  @media (max-width: $screen-sm-min) {
    display: block;
  }

  &:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  &:last-of-type {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &:nth-of-type(odd) {
    background-color: white;
  }

  &:nth-of-type(even) {
    background-color: $iceberg-white;
  }

  div {
    p {
      color: $marengo-grey;

      &:last-of-type {
        margin-bottom: 0;

        @media (max-width: $screen-sm-min) {
          margin-bottom: $spacing-tiny;
        }
      }
    }

    @media (max-width: $screen-sm-min) {
      .btn {
        margin-top: $spacing-small;
      }
    }
  }
}