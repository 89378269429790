.bank-account-card {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr 2fr;
  padding: $spacing-medium $spacing-medium;
  align-items: center;
  grid-gap: $spacing-small;

  @media (max-width: $screen-xs-max) {
    grid-template-columns: 1fr;

    .justify-content-end {
      justify-content: flex-start;
    }
  }

  &:nth-child(odd) {
    background-color: $iceberg-white;
  }

  &:nth-child(even) {
    background-color: $beluga-white;
  }

  p {
    margin-bottom: 0;
    padding: 0 $spacing-tiny;
    font-weight: bold;
  }

  .info-tip-container {
    p {
      display: contents;
    }
  }
}

#odt-amount-purpose {

  .has-success {

    .input-group-addon {
      background-color: #eee;
      color: #555;
    }
  }

  .checkbox {
    color: $marengo-grey;

    p {
      color: $marengo-grey;
    }
  }
}

.odt-exchange-rates-page {

  .flex-row p:nth-child(2),
  ul li,
  .checkbox label {
    color: $marengo-grey;
  }

  .flex-row {

    p {
      &:nth-child(1) {
        color: $abyss-blue;
      }
    }
  }

  ul {
    padding-left: 0;

    li {
      list-style-position: inside;
      padding-bottom: $spacing-small;
    }
  }

  .checkbox {
    margin-bottom: $spacing-small;
  }
}

.odt-transfer-details-page,
.odt-transfer-confirmation-page {

  .card-header {
    background-color: transparent;
    border-bottom: none;
    padding: $spacing-small;
  }

  .grid-cols-1 {
    grid-template-columns: 1fr;

    .card-header {
      display: none;
    }

    &.display-card-header {
      .card-header {
        display: block;
      }
    }
  }

  .card-body .flex-row {
    padding: 5px 0;

    label,
    p {
      margin-bottom: 0;
    }
  }

  .text-right {
    font-weight: bold;
  }

  .checkbox {
    margin-top: $spacing-medium;

    label {
      color: $marengo-grey;
    }
  }
}

.print {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;

  p {
    color: $brightwell-blue;
  }
}