.v2 {
  .sensitive {
    -webkit-text-security: disc !important;

    @-moz-document url-prefix() {
      font-family: "dotsfont", sans-serif !important;
      font-size: 16px !important;
    }

    @media all and (-ms-high-contrast: none),
    (-ms-high-contrast: active) {
      font-family: "dotsfont", sans-serif !important;
      font-size: 16px !important;
    }
  }

  input[type="date"] {
    position: relative;
  }

  input[type="date"]:before {
    content: attr(placeholder);
    position: absolute;
    top: 3px;
    left: 9px;
    right: 0;
    bottom: 0;
    background: #fff;
    color: $sky-grey;
    pointer-events: none;
    line-height: 1.5;
    padding: 0 0.5rem;
    font-weight: normal;
    width: 90%;
    height: 26px;

    @-moz-document url-prefix() {
      display: none;
      position: static;
      content: "";
    }
  }

  input[type="date"]:focus:before,
  input[type="date"]:not([value=""]):before {
    display: none;
  }

  &.pin-reset {
    .has-error .form-control {
      border-color: $torch-red;
    }

    .mobile-date {
      margin-right: 0 !important;
    }

    .text-danger {
      line-height: 24px;
      font-weight: 600;
      display: block;

      &.input-errors {
        font-weight: 500;
      }
    }

    .form-group {
      .text-danger {
        margin-top: 0;
      }
    }

    .form-control {
      color: $ocean-blue;
      border-color: $sky-grey;
      box-shadow: none;
      font-size: $font-size-default;
      font-weight: bold;
      border-width: 1.5px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-weight: normal;
        color: $sky-grey;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        font-weight: normal;
        color: $sky-grey;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        font-weight: normal;
        color: $sky-grey;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        font-weight: normal;
        color: $sky-grey;
      }
    }
  }

  .input-wrapper {
    &.pin-input-wrapper {
      margin: 0 0 20px 0;
      padding: 0;
      clear: both;
      overflow: hidden;

      @media (max-width: $screen-sm-min) {
        .text-danger {
          text-align: center;
        }
      }
    }
  }

  .pin-reset-input-group {
    width: 40%;
    float: left;
  }

  .mobile-pin-inputs {
    text-align: center;
    margin-top: 70px;
    position: relative;

    &__error {
      margin-top: 10px;
      text-align: center;
      display: block !important;
    }
  }

  .input-help-link {
    float: right;
    color: $ice-blue;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    text-decoration: underline;
    border: none;
    background: none;

    &:disabled {
      color: $sky-grey;
    }
  }

  .pin-input {
    border: none;
    border-bottom: 1.5px solid $shark-grey;
    border-radius: 0;
    background: none;
    margin-right: 10px;
    width: 20%;
    text-align: center;
    font-size: 30px;
    position: relative;
    -webkit-text-security: disc;

    @-moz-document url-prefix() {
      font-family: "dotsfont";
      font-size: 16px;
    }

    /// Hides the incrementer on firefox
    &[type="number"] {
      -moz-appearance: textfield;
    }

    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:focus {
      border-bottom: 1.5px solid $ice-blue;
    }
  }

  .mobile-input-error {
    -webkit-animation-name: shake-error;
    /* Safari 4.0 - 8.0 */
    -webkit-animation-duration: 0.35s;
    /* Safari 4.0 - 8.0 */
    animation-name: shake-error;
    animation-duration: 0.35s;
    border-color: $error-red !important;
  }

  @keyframes shake-error {
    0% {
      left: 0;
    }

    20% {
      left: 5px;
    }

    40% {
      left: -5px;
    }

    60% {
      left: 5px;
    }

    80% {
      left: -5px;
    }

    100% {
      left: 0;
    }
  }

  .flow-page-block {
    &__alert {
      position: relative;

      span.BWIcon:first-child {
        position: absolute;
        float: none;
        top: 50%;
        margin-top: -9px;
        left: 0;
      }

      &-message {
        float: none;
        margin-left: 29px;
      }
    }
  }
}

/// This belongs in layout.scss but the styling
/// in the mockup doesn't match what is currently
/// in production. Need to either update layout.scss
/// or use these new styles going forward
/// I do not like having to use importants !!
.v2.flow-screen {
  margin-bottom: 30px;
}

.v2 .flow-screen {
  margin-bottom: 30px;

  &__header {
    font-style: normal;
    font-weight: normal;
    color: $marengo-grey !important;
    font-size: 36px !important;

    @media (max-width: $screen-sm-min) {
      margin-top: $spacing-medium;
      font-size: 28px !important;
    }
  }
}

.flow-page-block {
  padding: $spacing-medium;
  border: 1.5px solid $sky-grey;
  border-radius: 5px;
  background: $iceberg-white;
  margin-top: 30px;
  margin-bottom: 50px;

  @media (max-width: $screen-sm-min) {
    padding: $spacing-small $spacing-small $spacing-medium;
    border: 1.5px solid $sky-grey;
    margin: 0;
    margin-bottom: $spacing-large;

    .form-control {
      font-size: 16px !important;
    }
  }

  &__title {
    margin: 0 0 30px 0 !important;
    font-style: normal;
    font-weight: bold;
    font-size: 22px !important;
    line-height: 120% !important;
    color: $ocean-blue;

    @media (max-width: $screen-sm-min) {
      font-weight: 600 !important;
      font-size: 20px !important;
      line-height: 150% !important;
      margin-bottom: $spacing-small !important;
    }
  }

  &__intro-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: $marengo-grey;
    margin-bottom: $spacing-medium;

    @media (max-width: $screen-sm-min) {
      font-weight: 600 !important;
      font-size: 14px !important;
      line-height: 150% !important;
      margin-bottom: $spacing-small !important;
    }
  }

  &__alert {
    color: $torch-red;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 16px;
    overflow: hidden;

    span.BWIcon:first-child {
      float: left;
      margin-right: 10px;
    }

    &-message {
      position: relative;
      top: -1px;
      max-width: 90%;
      float: left;
    }
  }
}

.action-row {
  padding: 20px 0;
  overflow: hidden;
  clear: both;

  @media (max-width: $screen-sm-min) {

    a,
    button {
      margin-bottom: 20px;
      float: none;
      width: 100%;
      text-align: center;
    }
  }

  &__forward {
    float: right;

    @media (max-width: $screen-sm-min) {
      float: none;
    }
  }

  &__backward {
    float: left;

    @media (max-width: $screen-sm-min) {
      float: none;
    }

    a {
      display: inline-block;
      margin: 20px;

      &.spacing-none {
        margin: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.pin-success-text {
  text-align: center;

  &__header {
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    color: $ocean-blue;
    margin: 20px 0 20px 0;
  }

  &__sub-text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: $ocean-blue;
    margin-bottom: 50px;
  }
}

.pin-success__return-dashboard,
.flowbutton__return-dashboard {
  letter-spacing: 0.5px;
  text-decoration: none !important;
}

.info-tip {
  border: none;
  background: none;
  display: inline-block;
}