.CardEnrollmentInstructions {
  visibility: visible;
  overflow: visible;
  display: block;
  margin: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  -webkit-transform-origin: 0px 0px;
  z-index: 34;
  background-color: #e4f5f6;
  border: 0 none;
  line-height: normal;
  color: black;
  padding: $spacing-default;

  p {
    line-height: 0;
    white-space: normal;
    word-wrap: break-word;
    margin: 0;
    padding: 0;
    border: 0 none;
    font-size: 12px;
    /*font-family: Arial;*/
  }

  .page-instructions-2 {
    line-height: 13px;
    text-transform: uppercase;
  }

  .bestPractices {
    padding-bottom: 5px;
    display: block;
  }

  .line-spacing-1 {
    line-height: 17.5px;
    text-decoration: none;
  }
}