.receipt {
  max-width: 600px;
  margin: auto;

  h2,
  .download>p {
    margin-left: 5px;
  }

  .provider-logo {

    img {
      max-width: 150px;
    }
  }

  sup {
    color: $marengo-grey;
  }

  a.spacing-none {
    margin: 0;
  }

  &-footer {
    position: fixed;
    background: $white;
    bottom: 0;
    right: 0;
    left: 0;
    border-top: 1.5px solid $sky-grey;
    padding: 15px;
    z-index: 999;

    .container {
      max-width: 600px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {

        &:first-of-type {
          flex: 1;
        }

        &:nth-of-type(2) {
          margin-left: $spacing-small;
          flex: 2;
        }
      }
    }
  }
}