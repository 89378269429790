.login-support {
  p {
    font-size: $font-size-xl;
  }

  p,
  p.small {
    color: $marengo-grey;
  }

  .content {
    p > b {
      color: $ocean-blue;
    }
  }
}

.login-support-email {
  .page-block {
    @media (max-width: $screen-sm-min) {
      padding: 0;
    }
  }

  .v2 {
    p.large {
      font-size: $font-size-xl;
      line-height: 1.7;
      font-weight: normal;
    }

    label {
      color: $marengo-grey;
    }
  }

  .action-row__backward a {
    margin: 0;
  }
}
