// Full site styles:
html,
body {
  height: 100%;
  width: 100%;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  background: white;
}

// AppInsights Telemetry library inserts an empty div between #app element and the rest of the app, causing an issue with the footer not displaying at the bottom of the page
// as the footer's parent element is no longer a flex element that has a min-height: 100vh style
#full-height {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  background: white;
}

.container {
  flex: 1 0 auto;
}

body {
  color: $navy-blue;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  width: 100%;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $shark-grey;
}

a,
a:visited,
a:link {
  color: $ice-blue;
  text-decoration: none;
}

a:hover,
a:active {
  color: $brightwell-blue;
  text-decoration: underline;
}

.v2 a {
  text-decoration: underline;

  &.btn {
    text-decoration: none;
  }

  &.no-underline {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &.icon-small {

    img {
      margin-left: 3px;
      margin-bottom: 3px;
    }
  }
}

.sub-header {
  font-size: 18px;
  color: $navy-blue;
}

ul,
ol {
  padding-left: $spacing-medium;

  &.unstyled li {
    list-style: none;
  }
}

.page-title {
  color: $shark-grey;

  &.marengo-grey {
    color: $marengo-grey;
  }
}

.right {
  float: right;
}

.left {
  float: left;
}

.center {
  text-align: center;
}

.center-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.info-icon {
  color: $blue;
  background: $white;
  cursor: pointer;
  text-decoration: none;
  margin: 0 5px;
}

.info-icon:hover,
.info-icon:active {
  text-decoration: none;
  color: $blue;
}

.label-offset {
  margin-top: 25px;
}

.inline-block {
  display: inline-block;
}

.pad-vert-10 {
  padding: 10px 0 10px;
}

.form-adjust-top {
  margin-top: 20px;
}

.bold {
  font-weight: bold;
}

.font-regular {
  font-weight: 400;
}

.width-auto {
  width: auto;
}

.strike-through {
  text-decoration: line-through;
}

.rotate {
  transform: rotate(0);
  transition: transform ease-out 0.5s;

  &-90 {
    transform: rotate(90deg);
  }

  &-90-center {
    transform: rotate(90deg) translateX(50%);
    transform-origin: right;

  }
}

// This is useful for displaying raw HTML
.reset-styles {
  line-height: 140%;

  ul {
    display: block;
    list-style-type: disc;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 0;
    margin-right: 0;
    padding-left: 15px;

    &.westernunion {
      column-count: 2;

      @media (max-width: $screen-sm-min) {
        column-count: 1;
      }
    }
  }

  li {
    list-style-type: disc;
    padding-left: 10px;
  }

  a {
    @media (max-width: $screen-sm-min) {
      word-break: break-word;
    }
  }
}

hr {
  border-top: 1.5px solid $sky-grey;

  &.small {
    border-top-width: 1px;
    margin: 10px 0;
  }

  &.beluga-white {
    border-color: $beluga-white;
  }
}

.spacing {
  &-top {
    @include spacing(margin-top);
  }

  &-bottom {
    @include spacing(margin-bottom);
  }

  &-left {
    @include spacing(margin-left);
  }

  &-right {
    @include spacing(margin-right);
  }
}

.padding {
  &-top {
    @include spacing(padding-top);
  }

  &-bottom {
    @include spacing(padding-bottom);
  }

  &-left {
    @include spacing(padding-left);
  }

  &-right {
    @include spacing(padding-right);
  }
}

.mobile-margin {

  &-none {
    @media (max-width: $screen-sm-min) {
      margin: 0;
    }
  }
}

.mobile-padding {

  &-default {
    @media (max-width: $screen-sm-min) {
      padding: 0 $spacing-default;
    }
  }

  &-medium {
    @media (max-width: $screen-sm-min) {
      padding: 0 $spacing-medium;
    }
  }

}

// flex grid
.flex-row {
  display: flex;
  flex-direction: row;

  &.mobile-direction-column {
    @media (max-width: $screen-sm-min) {
      flex-direction: column;
    }
  }

  &.tablet-direction-column {
    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }

  .col {
    flex-grow: 1;
  }

  .col-flex-1 {
    flex: 1;
  }
}

.justify-content-center {
  justify-content: center;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.margin-bottom-15 {
  margin-bottom: 15px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.bg-color {

  &-brightwell-blue {
    background-color: $brightwell-blue;
  }

  &-iceberg-white {
    background-color: $iceberg-white;
  }

  &-sky-grey {
    background-color: $sky-grey;
  }

  &-white {
    background-color: white;
  }
}

.border-radius {
  border-radius: $border-radius;
}

.grayed-out {
  filter: grayscale(1);
}

.box-border {
  border: 1px solid $marengo-grey;
  padding: $spacing-tiny;
}

.marengo-grey {
  color: $marengo-grey;

  p {
    color: $marengo-grey;
  }
}

.grid {

  display: grid;
  gap: 15px;

  &-cols-1 {
    grid-template-columns: 1fr;

    &__important {
      grid-template-columns: 1fr !important;
    }
  }

  &-cols-2 {
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: $screen-sm-min) {
      grid-template-columns: 1fr;
    }
  }

  &.full-width {
    grid-template-columns: 1fr;
  }

  .row-1 {
    grid-row: 1;
  }

  .row-2 {
    grid-row: 2;
  }
}

.shark-grey {
  color: $shark-grey;
}

.max-w {
  &-100 {
    max-width: 100%;
  }

  &-200 {
    max-width: 200px;
  }

  &-360 {
    max-width: 360px;
  }
}

.display {
  &__block {
    display: block;
  }
}

.skip {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.skip:focus {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: $abyss-blue;
  border: 1px solid white;
  width: auto;
  height: auto;
  display: block;
  z-index: 10000;
  padding: 10px;
  border-radius: 25px;
  text-decoration: none;
  color: white;
}

div[role=dialog]:has(.hide) {
  display: none;
}