.privacy-policy {

  h1,
  h2,
  p {
    color: $abyss-blue;
  }

  h1,
  h2 {
    font-weight: bold;
  }

  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 20px;
  }
}