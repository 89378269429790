@mixin spacing($property) {
  &-default {
    @include spacing-setup($property, $spacing-default);
  }

  &-large {
    @include spacing-setup($property, $spacing-large);
  }

  &-medium {
    @include spacing-setup($property, $spacing-medium);
  }

  &-small {
    @include spacing-setup($property, $spacing-small);
  }

  &-tiny {
    @include spacing-setup($property, $spacing-tiny);
  }

  &-xs {
    @include spacing-setup($property, $spacing-xs);
  }

  &-none {
    @include spacing-setup($property, 0);
  }
}

@mixin spacing-setup($property, $space) {
  #{$property}: $space;

  &-sm {
    @media (min-width: $screen-sm-min) {
      #{$property}: $space;
    }
  }

  &-md {
    @media (min-width: $screen-md-min) {
      #{$property}: $space;
    }
  }
}