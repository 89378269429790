$message-block-measurement-sm: 10px;

.message-block {
  p.large {
    font-size: $font-size-xl;
    font-weight: 400;
  }

  h2 {
    margin-top: 0;
    margin-bottom: $spacing-medium;
  }
}

.message-block-footer {
  .btn:not(:last-child) {
    margin-right: 15px;
  }

  @media (max-width: $screen-xs-max) {
    .btn {
      width: 100%;
      margin: 0;
      margin-bottom: $message-block-measurement-sm;
    }
  }
}
