@import "../common/variables";

.money-transfer {
  @media (max-width: $screen-sm-min) {
    height: 100%;
  }

  .restrictions {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
    padding-bottom: $spacing-small;

    border-bottom: 1.5px solid $sky-grey;
  }

  .reference-number {
    color: $ocean-blue;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;

    img {
      margin-left: $spacing-tiny;
    }
  }

  .disabled {
    opacity: 0.5;
  }

  .right-arrow {
    font-size: 32px;
    margin-top: 8px;
    display: flex;
  }

  .text-danger {
    margin-top: 5px;
  }

  .has-error .form-control {
    border-color: inherit;
  }

  .input-group-addon {
    font-weight: bold;
    font-size: 12px;
  }

  .has-error .input-group-addon {
    color: $marengo-grey;
    background-color: inherit;
    border-color: inherit;
  }

  .has-success .input-group-addon {
    color: $marengo-grey;
    background-color: inherit;
    border-color: inherit;
  }

  .has-success .form-control:focus {
    border-color: inherit;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px;
  }

  &__footer-links {
    display: flex;

    &.column {
      flex-direction: column;

      a.footer-directive-link {
        margin-bottom: $spacing-tiny;
      }
    }

    &.blue {

      a.footer-directive-link {
        color: $brightwell-blue;
      }
    }

    a.footer-directive-link {
      color: $marengo-grey;
      font-size: 12px;
      margin-right: $spacing-tiny;
      font-weight: bold;
    }

    @media (max-width: $screen-sm-min) {
      flex-direction: column;

      a.footer-directive-link {
        margin-bottom: $spacing-tiny;

        &:last-of-type {
          margin-bottom: 0;
        }
      }

    }
  }

  #country-amount-form {
    max-width: 360px;

    .amount-row {
      display: flex;
      margin-bottom: $spacing-large;

      .form-group:first-child {
        max-width: 200px;
        min-width: 153px;
      }

      .right-arrow {
        text-align: center;
        max-width: 100px;
        min-width: 25px;
        flex-grow: 1;
        justify-content: center;
        align-self: flex-start;
        margin-top: 35px;
      }

      .form-group:last-child {
        max-width: 100px;
        min-width: 100px;
      }
    }

    .actions {
      margin-top: $spacing-medium;

      // Spacing changes based on the existance of an error message
      &.error {
        margin-top: 0;
      }

      .get-rates-button {
        min-width: 260px;

        @media (max-width: $screen-sm-min) {
          width: 100%;
        }
      }
    }
  }

  .field-category {
    .section-header {
      margin-bottom: $spacing-default;
      font-size: $font-size-lg;
      color: $ocean-blue;
      font-weight: bold;
    }
  }

  .review {
    .terms-and-conditions {

      &,
      & * {
        color: $shark-grey;
        font-size: 10px;
      }

      a {
        color: $brightwell-blue;
      }
    }

    .card {

      label,
      .value {
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .total {

        label,
        .value {
          font-size: 16px;
          color: $ocean-blue;
          font-weight: bold;
        }
      }

      .disclaimer {
        margin-bottom: 0;
      }
    }

    .terms-footer {
      position: fixed;
      background: $white;
      bottom: 0;
      right: 0;
      left: 0;
      border-top: 1.5px solid $sky-grey;
      padding: 15px;
      z-index: 999;

      .container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }

      .checkbox {
        flex: 1;
      }

      .send-button-container {
        margin-left: auto;

        &,
        button {
          @media (max-width: $screen-sm-min) {
            margin-top: $spacing-tiny;
            width: 100%;
          }
        }
      }
    }

    // Western Union specific footer
    .terms-footer.western-union {
      position: static;
      background: transparent;
      bottom: auto;
      right: auto;
      left: auto;
      border-top: 0;
      padding: 0;
      margin-top: $spacing-default;

      label {
        span {
          font-size: 12px;
        }
      }
    }
  }

  .mt-display {
    padding: 5px 15px 5px 0;
    font-weight: bold;

    &.multiline-text {
      grid-column: 1/4;
      font-weight: 400;
    }

    @media (max-width: $screen-sm-min) {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-right: 0;
      margin-bottom: 5px;
    }

    label {
      font-weight: bold;
    }

    .mt-label {
      font-style: normal;
      font-size: $label-value-md;
      line-height: 18px;
      color: $shark-grey;
      margin-bottom: 0;
    }

    .value {
      font-style: normal;
      font-size: $label-value-md;
      line-height: 18px;
      color: $navy-blue;
      word-wrap: break-word;

      &.new {
        color: $alert-green;
      }
    }

    .p.word-wrap {
      word-wrap: break-word;
    }
  }

  /* Rate Card Specifics */
  /* Is this CSS for ALL things MT or common MT*/
  .rate-card {
    border: 1.5px solid $sky-grey;
    border-radius: 5px;
    padding: 15px 25px;
    background-color: $white;
    margin-bottom: $spacing-small;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;

    @media (max-width: $screen-sm-min) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: $spacing-small;

      .mt-display {
        padding-top: 0;
        padding-bottom: 0;
      }

      .exchange-rate,
      .applied-promo-text {
        width: 100%;
        margin-bottom: $spacing-tiny;
      }
    }

    .provider-logo {
      grid-area: 1/1/2/2;
      margin-bottom: $spacing-small;
      padding-right: $spacing-medium;

      @media (max-width: $screen-sm-min) {
        width: 50%;
        padding-right: 0;
        order: 1;
      }

      img {
        max-width: 100%;
      }
    }

    .exchange-rate {
      grid-area: 1/2/2/4;

      @media (max-width: $screen-sm-min) {
        display: flex;
        justify-content: space-between;
        order: 5;

        span {
          text-align: right;
        }
      }

      label,
      span {
        font-weight: bold;
      }
    }

    .above-promo {
      width: 100%;
      order: 2;

      hr {
        margin-top: 0;
      }
    }

    .below-promo {
      width: 100%;
      order: 4;
    }

    .promo-link {
      grid-area: 1/4/2/5;
      text-align: right;

      @media (max-width: $screen-sm-min) {
        order: 2;
        margin-bottom: 0;
        text-align: left;
      }

      .btn-link {
        color: $brightwell-blue;
        font-weight: bold;
      }
    }

    .applied-promo-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-bottom: 5px;

      @media (max-width: $screen-sm-min) {
        width: 100%;
        order: 2;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-bottom: 0;

        button {
          margin-left: auto;
        }
      }

      .promo-code-text {
        margin: 0 5px;
      }
    }

    .applied-promo-text {
      width: 100%;
      order: 3;
      text-align: left;
      font-weight: 500;
      color: $marengo-grey;
      font-size: 12px;
      margin-top: 5px;
      margin-bottom: 0;
    }

    .discount-applied {
      grid-area: 4/1/5/2;

      @media (max-width: $screen-sm-min) {
        width: 100%;
        order: 12;
        text-align: left;
      }
    }

    .fields-section {
      grid-column-start: 1;
      grid-column-end: 4;
      grid-row-start: 2;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      @media (max-width: $screen-sm-min) {
        width: 100%;
        display: block;
        order: 6;
      }

      .estimated-arrival {
        img {
          display: none;
        }

        @media (max-width: $screen-sm-min) {
          background-color: $iceberg-white;
          padding: 5px;
          order: 13;
          justify-content: flex-start;
          border: 1px solid $beluga-white;
          border-radius: 4px;

          img {
            display: block;
            margin-right: 5px;
          }

          label {
            display: none;
          }

          div.p {
            color: $marengo-grey;
          }
        }
      }
    }
  }



  .promo-code {
    grid-area: 4/2/5/3;
    display: block;
    justify-content: space-between;

    @media (max-width: $screen-sm-min) {
      order: 12;
      display: flex;
    }

    label {
      display: inline-block;
    }
  }

  .button-cell {
    grid-area: 2/4/3/5;
    height: 100%;

    @media (max-width: $screen-sm-min) {
      width: 100%;
      order: 8;

      .btn {
        width: 100%;
      }
    }
  }

  .error {
    order: 2;
  }
}

// Provider specific rate-card styling
.rate-card.WesternUnion {

  .discount-applied,
  .total-cost .strike-through,
  .promo-code,
  .tax {
    display: none;
  }

  .estimated-arrival {

    div {
      sup {
        @media (min-width: $screen-sm-min) {
          display: none;
        }
      }
    }
  }
}

.mt-sub-header {
  font-weight: bold;
  font-size: $label-value-lg;
  line-height: 24px;
  color: $marengo-grey;
  padding-top: 10px;

  @media (max-width: $screen-sm-min) {
    font-size: $label-value-md;
  }
}

.mt-sub-header-md {
  font-weight: bold;
  font-size: $label-value-lg;
  line-height: 27px;

  @media (max-width: $screen-sm-min) {
    font-size: $label-sub-header;
  }
}

.mt-sub-header-sm {
  font-size: $label-sub-header;
  font-weight: 600;
  line-height: 24px;
  color: $marengo-grey;
  padding-top: 10px;

  @media (max-width: $screen-sm-min) {
    font-size: $label-value-sm;
  }
}

.mt-link {
  text-decoration: underline !important;
  padding-top: 10px;
  font-weight: bold;
}

.navRow {
  padding: 10px 0px;
  line-height: 50px;

  @media (max-width: $screen-sm-min) {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    .one {
      order: 1;
      padding-bottom: 20px;

      .btn {
        width: 100%;
      }
    }

    .two {
      order: 2;

      .btn {
        width: 100%;
      }
    }
  }
}

.rate-errors {
  padding: $padding-default 0 25px 0;
  border-bottom: 1.5px solid $sky-grey;
  margin-bottom: $spacing-tiny;
}

.disclosures {
  font-size: $label-text-sm;
  line-height: 13px;
  color: $shark-grey;
  padding: 17px 0 5px 0;

  div {
    padding-bottom: 5px;
  }

  sup {
    font-weight: bold;
  }

  @media (max-width: $screen-sm-min) {
    font-size: $label-text-xs;
  }
}

.logo-name {
  color: $navy-blue;
  padding-left: 15px;
  font-size: $label-header-sm;
  font-weight: bold;
  line-height: 30px;
}

.button-cell {
  text-align: right;

  @media (max-width: $screen-sm-min) {
    text-align: left;
    padding-top: $padding-default;
  }
}

.promo-modal {
  .form-group {
    margin-bottom: 0;
  }
}

.mt-user-profile {
  background: $white;
  /* Sky Grey */
  padding: $padding-default;
  border: 1.5px solid $sky-grey;
  box-sizing: border-box;
  border-radius: 5px;
  margin: $spacing-small 0 $spacing-medium 0;
}

.mt-instruction {
  display: flex;
  color: $ocean-blue;
  margin-bottom: 20px;

  .icon {
    width: 20px;
  }

  &> :last-child {
    margin-left: $spacing-tiny;

    .title {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 7px;
    }

    .content {
      font-size: $font-size-default;
    }
  }
}

.mt-pickup-instructions {
  .slider-button {
    text-decoration: underline;
    font-weight: bold;
  }

  .slider-button img {
    transition: transform ease-out 0.3s;
  }

  &.open .slider-button img {
    transform: rotate(90deg);
  }

  .slider {
    margin-top: $spacing-tiny;
  }

  .instructions-list {
    margin-top: $spacing-medium;
  }
}

.disclaimer {
  margin-bottom: 5px;
  color: $shark-grey;
  font-size: 10px;
}

.display-fields {
  margin: $spacing-default 0 0 0;

  .field {
    padding: 7px 0;

    &:not(:last-child) {
      border-bottom: 1px solid $sky-grey;
    }

    label {
      text-align: left;
      padding: 0;
      display: block;
      margin-bottom: 0;
    }

    .value {
      font-size: 14px;
      color: $ocean-blue;
      text-align: right;

      &.text-danger {
        color: $error-red;
      }
    }
  }
}

// }