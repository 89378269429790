.btn-primary {
  background-color: $blue;
}

.btn-outline-primary {
  border-color: $blue;
  border-width: 2px;
  background-color: $white;
  color: $blue;
}

.btn-outline-danger {
  border-color: $error-red;
  border-width: 2px;
  background-color: $white;
  color: $error-red;
}

.btn-spacer {
  margin-bottom: 15px;
}

.text-danger {
  color: $error-red;
}

.text-warning {
  color: $yellow;
}

.text-muted {
  color: $marengo-grey;
}

.has-error .form-control,
.v2 .form-group .has-error .form-control {
  border-color: $error-red;
}

// When regular bootstrap grid has column sizes that are mismatched
// (For example two form cells where one has a validation error)
// the grid will leave a huge blank hole. This is a result of floating.
// Replacing float with inline-block for these scenarios seemed to work.
.no-float-row {
  [class^="col-"] {
    float: none;
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .navbar-right {
    //float: right!important;
    margin-right: 0;
  }
}

@media (max-width: $screen-sm-min) {
  nav.navbar {
    margin-bottom: 0;
  }

  .flex-barrier {
    height: 100%;
    flex: 1 1 auto;
    display: flex;

    &>.container {
      flex: 1 1 auto;
    }
  }
}

/*** taken from http://blog.osmosys.asia/2017/01/05/prevent-ios-from-zooming-in-on-input-fields/ ***/
/*** Styles added to fix the issue with zoom in on iphone ***/
/* iPhone < 5: */
@media screen and (device-aspect-ratio: 2/3) {

  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {

  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* iPhone 6, iPhone 6s, iPhone 7 portrait/landscape */
@media screen and (device-aspect-ratio: 375/667) {

  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

/* iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus portrait/landscape */
@media screen and (device-aspect-ratio: 9/16) {

  select,
  textarea,
  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"] {
    font-size: 16px;
  }
}

.alert-info {
  background-color: $iceberg-white;
  border-color: $beluga-white;
  color: $marengo-grey;

  &.alert-bkgrd-white {
    background-color: white;
  }

  p {
    color: $marengo-grey;
  }
}

.no-float {
  float: none;
}