.table {
  font-size: 14px;

  .row {
    padding: 15px 0;
  }

  .row:nth-child(odd) {
    background-color: $silver;
  }

  .table-header {
    font-weight: bold;
    font-size: 1.2em;
  }

  .action-link.btn.tiny {
    min-width: 50px;
    font-size: 8px;
    padding: 8px;

    @media (min-width: $screen-md-min) {
      font-size: 12px;
      padding: 10px;
    }
  }
}

.table.old-navigator-style {
  margin-bottom: 0;

  th,
  td {
    &:last-of-type {
      text-align: right;
    }
  }

  thead {
    background-color: $beluga-white;

    th {
      font-weight: 500;
      color: $navy-blue;
    }
  }

  td {
    color: $marengo-grey;

    p {
      margin-bottom: 5px;

      &.subName {
        font-size: 12px;
      }
    }
  }
}

.overflow-scroll {
  @media (max-width: $screen-sm-min) {
    overflow-x: scroll;
  }
}