@import "variables.scss";

// Modals
// This is the first time we're applying any of these new styles
// Most of these should probably be extracted into more generic site styles once we see them get reused.

$modal-measurement-sm: 10px;
$modal-measurement-md: 15px;
$modal-measurement-lg: 25px;

$modal-border-radius: 30px;

$modal-header-font-size: 18px;

$modal-close-button-font-size: 24px;
$modal-close-button-color: $shark-grey;

$modal-body-font-size: $font-size-default;
$modal-body-line-height: 24px;

$close-button-size: 25px;

/* Overriding styles that come with react-responsive-modal */
.modal-dialog {
  background: none;
  box-shadow: none;
  padding: none;
  max-width: none;

  // The bootstrap style for this kills centering in the react-responsive-modal.
  // This resets the margin to what react-responsive-modal expects
  margin: auto;

  @media (min-width: $screen-sm-min) {
    width: 720px;

    &.small {
      max-width: 400px;
    }

    &.medium {
      width: 550px;
    }
  }

  @media (max-width: $screen-xs-max) {
    max-width: 400px;
    width: 100%;
  }

  &.table {
    @media (min-width: $screen-sm-min) {
      min-width: 720px;
      width: auto;
    }

    @media (max-width: $screen-xs-max) {
      max-width: 400px;
      width: auto;

      .modal-body {
        overflow-x: auto;
      }
    }
  }

  &.modal-title-flex {

    .modal-title {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 22px;
        margin-right: 10px;
      }
    }
  }

  .modal-content {
    border-radius: $modal-border-radius;
  }

  .modal-header {
    padding: $modal-measurement-md (
      $modal-measurement-lg + $close-button-size) $modal-measurement-md (
      $modal-measurement-lg + $close-button-size
    );
  min-height: $close-button-size + $close-button-size;
  text-align: center;
}

.modal-header .close {
  color: $modal-close-button-color;
  font-size: $modal-close-button-font-size;
  opacity: 1;
  position: absolute;
  right: $spacing-small;

  &:hover {
    color: $sky-grey;
  }

  &:active {
    color: $marengo-grey;
    outline: none;
  }
}

&.large-content {
  .modal-body {
    font-size: $font-size-lg;
  }
}

.modal-title {
  color: $ocean-blue;
  font-weight: 900;
}

.modal-body {
  color: $ocean-blue;
  font-size: $modal-body-font-size;
  padding: $modal-measurement-md $modal-measurement-lg;
  line-height: $modal-body-line-height;

  &.loading {
    padding: $spacing-large 0;
    text-align: center;
  }
}

.modal-footer {
  padding: $modal-measurement-md $modal-measurement-lg $modal-measurement-lg $modal-measurement-lg;
  border-top: none;

  .btn:not(:last-child) {
    margin-right: 15px;
  }

  @media (max-width: $screen-xs-max) {
    padding: $modal-measurement-md;
    padding-bottom: $modal-measurement-lg;
    text-align: center;

    .btn {
      width: 100%;
      margin: 0;

      &:not(:first-child) {
        margin-top: $modal-measurement-md;
      }
    }
  }
}

&.small {
  max-width: 400px;
  text-align: center;

  .modal-footer .btn {
    width: 100%;
    margin: 0;

    &:not(:first-child) {
      margin-top: $modal-measurement-sm;
    }
  }
}

&.sticky-footer {
  .modal-body {
    max-height: 400px;
    overflow-y: scroll;

    @media (max-width: $screen-xs-max) {
      max-height: 300px;
    }
  }

  .modal-footer {
    border-top: 1px solid #e5e5e5;
  }
}
}

.modal-dialog.small-form {

  &.left-aligned {
    text-align: left;

    .modal-header {
      text-align: left;
    }
  }

  .modal-header {
    padding: $spacing-default calc(#{$spacing-medium} - #{$spacing-xs});

    .modal-title {
      font-size: 16px;
      color: $marengo-grey;
    }
  }

  .modal-content {
    border-radius: $border-radius;
  }

  .modal-body {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 0;
  }

  .modal-footer {
    display: flex;

    &.visible-xs {

      @media (max-width: 767px) {
        display: flex !important;

        .btn {

          &:first-of-type {
            order: 2;
          }

          &:last-of-type {
            order: 1;
          }
        }
      }

    }

    .btn {
      min-width: auto;
      padding: $spacing-tiny 0;
      margin-top: 0 !important;

      &:first-of-type {
        margin-right: $spacing-tiny;
      }
    }
  }
}

.modal-dialog.header-icon {

  .modal-header {
    display: flex;
    align-items: center;

    img {
      margin-right: $spacing-tiny;
    }
  }

  &.center {
    .modal-header {
      justify-content: center;
    }
  }
}