.wage-statements {
  &-list {
    list-style-type: none;
    padding: 0;
  }

  a.href-button {
    text-decoration: none;
    color: $white;
  }
}

.statement-item {
  border-bottom: 1px solid $silver;
  padding: 15px;

  a {
    color: $abyss-blue;
    text-decoration: underline;
  }
}

.statement-item:last-child {
  border-bottom: 0;
}

.wage-statement-item {
  border-bottom: 1px solid $silver;
  padding: $spacing-tiny;
  padding-left: 0;
}

.wage-statement-link {
  display: block;
  overflow: hidden;
  padding: $spacing-tiny;
  padding-left: 0;
}