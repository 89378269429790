.accordion.panel {

  .panel-heading {
    background-color: white;
    color: $abyss-blue;
  }

  .list-group-item {
    padding: $spacing-small;
    color: $abyss-blue;

    p {
      color: $abyss-blue;
      margin-bottom: 0;
    }

    span {
      font-size: 2rem;
    }

    details {
      width: 100%;

      summary {
        cursor: pointer;
      }

      &>p,
      &>div {
        margin-top: $spacing-medium;
      }
    }
  }
}