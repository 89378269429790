$headerPadding: 5px 15px;
$borderWidth: 1.5px;
$border: $borderWidth solid $sky-grey;
$borderRadius: 5px;
$page-block-horizontal-padding: $spacing-medium;

// Page blocks should be used to wrap all of the primary content on a page
// These render as grey boxes in desktop and become the backgorund in mobile
// The purpose of the page block is to contain all the content
// and keep the users focus in the middle of the screen
.page-block {
  background-color: $iceberg-white;
  padding: $spacing-small $page-block-horizontal-padding $spacing-medium $page-block-horizontal-padding;
  border: 1.5px solid $sky-grey;
  border-radius: $border-radius;
  margin-top: $spacing-medium;
  margin-bottom: $spacing-large;

  @media (max-width: $screen-sm-min) {
    padding: $spacing-small $spacing-small $spacing-medium;
    border: 1.5px solid $sky-grey;
    margin-top: 0;
    margin-bottom: $spacing-large;
  }

  &.sm {
    max-width: 375px;
    margin-left: auto;
    margin-right: auto;
  }
}

.content-block {
  margin-bottom: $spacing-default;
  padding: 0;

  &.highlight {
    background: white;
    border: 1.5px solid $sky-grey;
    border-radius: $border-radius;
    padding: $spacing-tiny $spacing-medium;

    @media (max-width: $screen-sm-min) {
      border: 0;
      border-radius: 0;
      border-top: 1.5px solid $sky-grey;
      border-bottom: 1.5px solid $sky-grey;
      margin-left: -$page-block-horizontal-padding;
      margin-right: -$page-block-horizontal-padding;
    }
  }
}

.page-layout-nav {
  overflow: hidden;

  &__content {
    width: 80%;
    float: left;
    border-left: 1px solid $sky-grey;
    padding-left: 30px;

    &.focused {
      border-left: none;
      width: 100%;
      float: none;
      padding-left: 0px;
    }

    @media (max-width: $screen-sm-min) {
      width: 100%;
      border-left: 0;
      padding: 0;
    }

    &.allow-overflow {
      @media (max-width: $screen-sm-min) {
        max-width: calc(100vw - 30px);
      }
    }
  }
}

.page-nav {
  width: 20%;
  padding-right: $spacing-tiny;
  float: left;
  padding-top: 20px;

  @media (max-width: $screen-sm-min) {
    width: 100%;
    padding: $spacing-small 0;
    background: #fff;
    border-bottom: 1px solid $sky-grey;
    padding-top: $spacing-medium;
  }

  &__header {
    font-size: $label-header-sm;
    color: $shark-grey;
    border-bottom: 1px solid $sky-grey;
    padding-bottom: $spacing-tiny;
    margin-bottom: $spacing-tiny;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    &-link {
      padding: 5px 0;
      display: inline-block;
      line-height: 150%;
      font-weight: bold;
    }

    &.heading {
      padding: 5px 0;
      font-weight: bold;
      color: $shark-grey;
    }

    &.indented {

      &-single {
        padding-left: $spacing-small;
      }

      &-double {
        padding-left: calc(#{$spacing-small} * 2);
      }
    }
  }
}

.container-md {
  max-width: 810px;

  @media (min-width: $screen-sm-min) {
    margin-left: auto;
    margin-right: auto;
  }
}

.border {

  &-top {
    border-top: $border;
  }

  &-right {
    border-right: $border;
  }

  &-bottom {
    border-bottom: $border;
  }

  &-left {
    border-left: $border;
  }
}

.flow-page-block__component__child {
  max-width: 360px;

  @media screen and (max-width: $screen-sm-min) {
    max-width: 100%;
  }
}

.v3 {
  .flow-page-block {
    background-color: #fff;
  }
}

.double-page-block-container {
  gap: 0;

  @media (min-width: $screen-md-min) {
    gap: $spacing-medium;
  }

  .flow-page-block {

    @media (max-width: $screen-sm-max) {
      width: 100%;
      padding: $spacing-default;
      margin-bottom: $spacing-default;
    }
  }
}