.navigatorTable {
    width: 100%;
}

 .navigatorTable th {
    padding: 15px 30px 0px 30px;
    background: #eaecee;
}

.navigatorTable td {
    padding: 15px 30px 15px 30px;
}

.navigatorTable input[type="button"] {
    font-weight: normal !important;
    font-size: 12px !important;
    height: 25px;
    margin: 0 !important;
    padding: 0 !important;
    min-width: 116px;
}

.navigatorTable tbody tr {
    border-bottom: 1px solid #b5b5b5;
    padding-top: 15px;
    padding-bottom: 15px;

    td:nth-of-type(2) {
        display: flex;
        justify-content: flex-end;
    }
}

.navigatorTable tr:last-of-type {
    border-bottom: none;
}