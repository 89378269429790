.label-icon {
  display: inline-block;
  margin-right: 5px;
  padding-bottom: 5px;
}

.label-icon-right {
  display: inline-block;
  margin-left: 10px;
}
