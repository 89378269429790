.enrollment-form.styled-modal {
    margin-top: 200px;
}

.enrollment-form.styled-modal .modal-content {
    border-radius: 30px !important;
}

.enrollment-form.styled-modal .modal-header {
    padding: 20px 15px 12px 15px;
    position: relative;
    border-bottom: 1px solid #c5d0de;
    line-height: 1.5;
}

.enrollment-form.styled-modal .close {
    border: 1.5px solid #8994a6;
    width: 21px;
    height: 21px;
    line-height: 20px;
    border-radius: 11px;
    position: absolute;
    right: 15px;
    top: 14px;
    margin: 0;
}

.enrollment-form.styled-modal .close span {
    font-size: 12px;
    position: absolute;
    top: -2px;
    left: 5px;
}

.enrollment-form.styled-modal .modal-title {
    text-align: center;
    font-weight: bold;
    color: #004780 !important;
}

.enrollment-form.styled-modal .modal-footer {
    border: none;
    text-align: center;
    padding-bottom: 30px;
}

.enrollment-form.styled-modal .modal-body {
    text-align: center;
    line-height: 25px;
}

.enrollment-form.styled-modal .modal-footer a {
    float: none;
    border-radius: 30px;
    padding: 17px 20px;
    font-size: 16px;
    min-width: 50%;
    margin-top: 0;
    font-weight: bold;
    display: inline-block;
}

.enrollment-form .support-link {
    text-decoration: underline !important;
}

@media (max-width: 425px) {
    .enrollment-form.styled-modal .modal-footer a {
        width: 100%;
    }
}