@import "variables.scss";

.btn {
  padding: 6px 20px;
  font-weight: $label-and-button-weight;
}

.btn-default,
.btn-primary,
#submit_btn {
  background: $ice-blue;
  color: white;
  text-decoration: none;
  box-shadow: 0px 10px 14px $ice-blue;
  border: 0;
}

.btn-default:hover,
.btn-primary:hover,
#submit_btn:hover {
  background: $brightwell-blue;
  color: white;
}

.btn-default:active,
.btn-primary:active,
.btn-primary:focus,
.btn-default:focus {
  background: $navy-blue;
  color: white;
}

.btn-secondary {
  background: white;
  color: $ice-blue;
  border: 2px solid $ice-blue;
  text-decoration: none;
  padding: 4px 20px;
}

.btn-secondary:hover {
  border: 2px solid $brightwell-blue;
  color: $brightwell-blue;
  background: white;
}

.btn-secondary:active,
.btn-secondary:focus {
  border: 2px solid $navy-blue;
  color: $navy-blue;
  background: white;
}

.btn-slim {
  background-color: white;
  color: $ice-blue;
  border: 0;
  line-height: 15px;
  padding: 5px 20px;
  font-weight: bold;
  border-radius: 5px;
}

.btn-slim:hover {
  background: $beluga-white;
  color: $ice-blue;
}

.btn-slim:active,
.btn-slim:focus {
  background: $sky-grey;
  color: $ice-blue;
}

.nav-next {
  float: right;
  border-radius: 27.5px;
}

.nav-back {
  padding-top: 10px;
  display: flex;
}

.btn-fat:hover {
  color: $white;
}

.btn-fat:disabled,
.btn-fat[disabled] {
  background: $sky-grey;
  color: $white;
  border-radius: 27.5px;
  padding: 20px;
  line-height: 15px;
}

.btn-copy {
  color: $abyss-blue;
  padding: 5px $spacing-tiny;

  img {
    height: 16px;
    margin-right: 5px;
  }
}

/* This is the wrapper for our new styles. Once CashPickup is removed this can be removed so all the styles are global. */
.v2 {

  // this feels wrong. let's revisit "disabling" a link
  a.disabled {
    color: $shark-grey;
    cursor: not-allowed;
    text-decoration: none !important;
  }

  .btn {
    position: relative;
    min-width: 200px;
    border-radius: 27.5px;
    padding: $padding-btn (
      $padding-btn * 2
    );
  $icon-size: 15px;
  $icon-spacing: 10px;
  display: inline-flex;
  justify-content: center;

  &.full-width {
    width: 100%;
  }

  &.bold {
    font-weight: bold;
  }

  &.vertical-align {
    align-items: center;
  }

  // These are the correct styles but they weren't added from day 1 so it looks weird on some pages
  // We should apply these as soon as possible.
  // font-size: 18px;
  // padding: $padding-btn $padding-btn;

  &.flex {
    width: 100%;
    max-width: 242px;

    @media (max-width: $screen-sm-min) {
      max-width: none;
    }
  }

  img {
    position: absolute;
    right: $padding-btn;
    align-self: center;

    &.icon-left {
      position: relative;
      // left: $padding-btn;
    }

    &.navIcon {
      float: right;
    }

    &.relative {
      position: relative;
      right: auto;
    }
  }

  &.block {
    display: flex;
    width: 100%;
  }

  &.block-small {
    @media (max-width: $screen-sm-min) {
      display: flex;
      width: 100%;
    }
  }

  &.small {
    min-width: 115px;
    padding: $padding-btn-small (
      $icon-size + $icon-spacing + $padding-btn
    );
}

&.tiny {
  min-width: 100px;
  padding: $padding-btn-small;
  font-size: 12px;
}

&.btn-primary {
  background: $interactive-blue;
  color: $white;
  box-shadow: 0 10px 14px rgba(51, 156, 242, 0.2);

  &:hover {
    background-color: $brightwell-blue;
  }

  &:disabled {
    background: $sky-grey !important;
    box-shadow: none;
  }

  &.warning {
    background-color: $warning-red;
  }
}

&.btn-default {
  background: $white;
  border: 2px $interactive-blue solid;
  color: $interactive-blue;
  box-shadow: none;

  &:active {
    border-color: $ocean-blue;
  }

  &:hover {
    background-color: $white;
    border-color: $brightwell-blue;
    color: $brightwell-blue;
  }

  &:disabled {
    background: $sky-grey !important;
    box-shadow: none;
  }
}

&.btn-tertiary {
  border: 1.5px solid $interactive-blue;
  background: $white;
  color: $interactive-blue;
  box-shadow: 0 10px 14px rgba(51, 156, 242, 0.2);

  &:hover {
    background-color: $iceberg-white;
    border: 1.5px solid $brightwell-blue;
    color: $brightwell-blue;
  }

  &:disabled {
    background: $white !important;
    border: 1.5px solid $sky-grey;
    color: $sky-grey;
    box-shadow: none;
  }
}

&.btn-white-outline {
  border: 1.5px solid white;
  background: transparent;
  color: white;

  &:hover {
    background: white;
    color: $brightwell-blue;
  }
}

&.btn-link {
  text-decoration: underline;
  padding: 0;
  min-width: 0;
}

&.loading {
  background: none !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  cursor: default;
  pointer-events: none;
}
}

.btn-link {
  color: $interactive-blue;

  &:hover {
    color: $brightwell-blue;
  }
}

.action-link {
  img {
    margin-right: 5px;
  }
}
}

button.no-styling {
  outline: 0;
  border: 0;
  background-color: transparent;
  padding: 0;
}

button.btn.no-styling.btn-primary {
  color: $interactive-blue;
  outline: 0;
  border: 0;
  background-color: transparent;
  padding: 0;
  box-shadow: none;

  &:hover {
    background-color: transparent;
    text-decoration: underline;
  }
}

button.display-contents {
  display: contents;
}