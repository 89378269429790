@import "./variables.scss";

.v2 {
  h1 {
    font-size: 36px;
    color: $marengo-grey;
    font-weight: 300;
    margin-top: $spacing-default;
    margin-bottom: $spacing-default;

    @media (max-width: $screen-sm-min) {
      font-size: 28px;
      margin-top: $spacing-medium;
    }

    &.alt {
      color: $ocean-blue;
      font-weight: bold;
    }
  }

  h2 {
    font-size: 22px;
    color: $ocean-blue;
    font-weight: bold;
    margin-top: $spacing-tiny;
  }

  h3 {
    font-size: 18px;
    color: $ocean-blue;
    font-weight: bold;

    &.alt {
      color: $marengo-grey;
    }
  }

  p.large {
    font-size: 16px;
    color: $marengo-grey;
    font-weight: 600;
  }

  p.large-normal {
    font-size: 16px;
    line-height: 1.6;
    color: $marengo-grey;
    margin-bottom: $spacing-medium;
  }

  p,
  p.small,
  .p,
  .p.small {
    font-size: 14px;
    color: $ocean-blue;
  }

  p.line-height-medium {
    line-height: 1.4;
  }

  div.x-small {
    font-size: 10px;
    color: $shark-grey;
  }

  p.x-small {
    font-size: 10px;
    color: $shark-grey;
  }

  p.font-12-px {
    font-size: 12px;
  }

  p.shark-grey {
    color: $shark-grey;
  }

  p.abyss-blue {
    color: $abyss-blue;
  }

  p.alert-green {
    color: $alert-green;
  }

  p.ocean-blue {
    color: $ocean-blue;
  }

  p.marengo-grey {
    color: $marengo-grey;
  }

  label {
    font-size: 14px;
    color: $shark-grey;
    font-weight: $label-and-button-weight;
  }

  *.error {
    color: $error-red;
  }

  .text-danger {
    color: $error-red;
  }
}

.separator-title {
  display: flex;
  align-items: center;

  &>* {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0.5rem;
  }

  &>h3 {
    font-weight: normal;
    color: $shark-grey;
    line-height: 140%;
    -webkit-font-smoothing: antialiased;
  }

  &::before,
  &::after {
    content: "";
    flex: 1;
    height: 1px;
    background-color: $shark-grey;
  }
}

.text-right-sm {
  @media (min-width: $screen-sm-min) {
    text-align: right;
  }
}

.text-right-md {
  @media (min-width: $screen-md-min) {
    text-align: right;
  }
}

p.white {
  color: white;
}

p.marengo-grey {
  color: $marengo-grey;
}

p.shark-grey {
  color: $shark-grey;
}

p.abyss-blue {
  color: $abyss-blue;
}

p.ocean-blue,
span.ocean-blue {
  color: $ocean-blue;
}

p.navigator-blue {
  color: $brightwell-blue;
}

p.navy-blue {
  color: $navy-blue !important;
}

.paragraph-small {
  font-size: 12px;

  p {
    font-size: 12px;
  }
}

.label-header-sm {
  font-size: $label-header-sm;
}