.v2 {
  $spacing-sm: 5px;

  form {
    &.small {
      max-width: 270px;
    }
  }

  .validation-error {
    color: $error-red;
    display: inline-block;
  }

  .error-list {
    color: $error-red;
    margin-bottom: $spacing-medium;
  }

  .form-group {
    text-align: left !important;

    &.no-label {
      padding-top: 26px;
    }

    .half-width {
      width: 100%;

      @media (min-width: $screen-sm-min) {
        width: 50%;
      }
    }

    label,
    p.label {
      color: $marengo-grey;
      margin-bottom: $spacing-tiny;
      font-size: $label-font-size;
      line-height: $label-font-size;
      font-weight: 700;

      &.auth-label {
        color: $shark-grey;
        font-weight: 500;
      }
    }

    .form-control {
      color: $ocean-blue;
      border-color: $sky-grey;
      box-shadow: none;
      font-size: $font-size-default;
      font-weight: bold;

      &:focus {
        box-shadow: none !important;
        border-color: $interactive-blue;
      }

      &:disabled {
        background: none;
        border-color: $sky-grey !important;
        color: $sky-grey;
      }

      &+.counter {
        font-size: $font-size-sm;
        color: $shark-grey;
        float: right;
      }
    }

    &.has-error {

      .form-control,
      .dynamic-dropdown>div,
      .date-field>div,
      .data-month-selector>div {
        border-color: $error-red;
      }
    }

    .text-danger {
      display: inline-block;
      margin-top: $spacing-tiny;
      font-size: $font-size-sm;
    }

    .help-block {
      font-size: $font-size-sm;
      min-height: $font-size-sm;
      line-height: $font-size-sm;
      margin-bottom: 0;
    }

    .input-group {
      border-color: $sky-grey;

      .input-group-addon {
        border-color: $sky-grey;
      }
    }

    .phone-number-field {
      display: flex;

      &>div {
        display: inline-block;
      }

      &>div:first-child {
        width: 100px;
        margin-right: $spacing-tiny;
      }

      &>div:last-child {
        flex-grow: 1;
      }

      .phone-country-code-select-option-label {
        width: 50px;
        display: inline-block;
        color: $shark-grey;

        &.selected {
          color: white;
        }
      }
    }

    .date-field {
      &__label {
        font-size: 12px;
        margin-bottom: 5px;
        font-weight: normal;
      }

      display: flex;

      &>div {
        display: inline-block;
      }

      &>div:nth-child(1) {
        flex-grow: 1;
        margin-right: $spacing-tiny;
      }

      &>div:nth-child(2) {
        width: 50px;
        margin-right: $spacing-tiny;
      }

      &>div:last-child {
        width: 70px;
      }
    }
  }

  .checkbox {
    $checkboxSize: 24px;
    margin-top: $checkbox-spacing;
    margin-bottom: 0px;

    &.spacing-bottom {
      margin-bottom: $spacing-small;
    }

    &:hover {

      label {
        &:before {
          background-color: $gray-100;
        }
      }

      input[type="checkbox"] {
        &:checked+label::before {
          border-color: $primary-600;
          background-color: $primary-600;
        }
      }
    }

    &:active {

      label {
        &:before {
          background-color: $gray-200;
        }
      }

      input[type="checkbox"] {
        &:checked+label::before {
          border-color: $primary-700;
          background-color: $primary-700;
        }
      }
    }

    &.md {

      &::before {
        height: 20px;
        width: 20px;
      }
    }

    label {
      position: relative;
      display: inline-block;
      padding-left: $checkboxSize + $spacing-tiny;

      &.marengo-grey {
        color: $marengo-grey;
      }

      &::before,
      &::after {
        position: absolute;
        content: "";

        /*Needed for the line-height to take effect*/
        display: inline-block;
      }

      /*Outer box of the fake checkbox*/
      &::before {
        height: $checkboxSize;
        width: $checkboxSize;
        border-radius: 3px;

        border: 1.5px solid $sky-grey;
        left: -0;
        top: -3px;
      }

      /*Checkmark of the fake checkbox*/
      &::after {
        height: $checkboxSize / 4;
        width: $checkboxSize / 2;
        border-left: 2px solid;
        border-bottom: 2px solid;
        border-color: #fff;

        transform: rotate(-45deg);

        left: $checkboxSize / 4;
        top: ($checkboxSize / 4) - 2;
      }
    }

    /*Hide the checkmark by default*/
    input[type="checkbox"] {
      opacity: 0;

      &+label::after {
        content: none;
      }

      &:checked+label::before {
        border-color: $primary-500;
        background-color: $primary-500;
      }

      /*Unhide on the checked state*/
      &:checked+label::after {
        content: "";
      }

      /*Adding focus styles on the outer-box of the fake checkbox*/
      &:focus+label::before {
        outline: $primary-500 auto 5px;
        outline-offset: 2px;
      }
    }
  }

  /** Remove spinners from number types **/
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="file"]::file-selector-button {
    background-color: $interactive-blue;
    color: white;
    box-shadow: 0 10px 14px rgba(51, 156, 242, 0.2);
    border-radius: 27.5px;
    padding: $padding-btn (
      $padding-btn * 2
    );
  font-size: $font-size-sm;
  font-weight: $label-and-button-weight;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: $brightwell-blue;
  }
}
}