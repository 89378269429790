.unsupported-browser {
  h2 {
    margin-bottom: $spacing-medium;
  }

  p {
    color: $marengo-grey;
    font-size: $font-size-xl;
    margin-bottom: $spacing-medium;
    line-height: 1.6;

    &.small {
      color: $marengo-grey;
      font-size: $font-size-lg;
    }

    a {
      font-weight: bold;
    }
  }

  hr {
    margin-top: $spacing-medium;
    margin-bottom: $spacing-medium;
  }

  .page-block {
    @media (max-width: $screen-sm-min) {
      padding: 0;
    }
  }
}

.browser-list {
  padding: 0;

  li {
    display: inline-block;
    font-size: $font-size-xl;
    font-weight: bold;
    margin-right: $spacing-large;
    margin-bottom: $spacing-small;
  }

  img {
    margin-right: $spacing-tiny;
  }

  a {
    text-decoration: none;
  }
}
