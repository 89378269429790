@media (min-width: 768px) {
  .form-horizontal .control-label {
    text-align: left;
  }
}

.padLeftZero {
  padding-left: 0;
  padding-right: 0;

  @media (min-width: 991px) {
    padding-right: 15px;
  }
}

.padRightZero {
  padding-right: 0;
  padding-left: 0;

  @media (min-width: $screen-md-min) {
    padding-left: 15px;
  }
}

.padLeftRightZero {
  padding-left: 0;
  padding-right: 0;
}

.enrollment-form {
  .bottomPadding15.row {
    margin-right: 0;
    margin-left: 0;

    @media (min-width: $screen-md-min) {
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  form {
    padding: 15px;
  }

  &.v2 h1 {
    padding-left: 0;
  }
}
