.forgot-password {
  .answer-security-question {
    .question-input {
      margin-top: $spacing-small;
    }
  }

  .input-errors {
    display: block !important;
  }

  .radio-btns {

    label {
      display: flex;
      align-items: center;

      &:hover {
        input[type="radio"] {
          border: 1px solid $gray-600;
          background-color: $gray-100;
        }
      }

      &:active {
        input[type="radio"] {
          border: 1px solid $gray-700;
          background-color: $gray-200;

          &:checked::before {
            background-color: $primary-600;
          }

          &:checked::after {
            transform: scale(1) translate(-50%, -50%);
          }
        }
      }

      &:focus {
        input[type="radio"] {
          border: 1px solid $primary-500;
          outline: 1px solid $primary-500;
          outline-offset: 1px;

          &::before {
            transform: scale(1);
          }

          &::after {
            transform: scale(1) translate(-50%, -50%);
          }

        }
      }
    }

    input[type="radio"] {
      /* Add if not using autoprefixer */
      -webkit-appearance: none;
      appearance: none;
      /* For iOS < 15 to remove gradient background */
      background-color: #fff;
      /* Not removed via appearance */
      margin: 0;
      font: inherit;
      color: currentColor;
      width: 24px;
      height: 24px;
      border: 1px solid $gray-500;
      border-radius: 50%;
      margin-right: $spacing-tiny;
      display: grid;
      place-content: center;
      position: relative;

      &::before {
        content: "";
        width: 24px;
        height: 24px;
        border-radius: 50%;
        transform: scale(0);
        box-shadow: inset 1em 1em var(--form-control-color);
        background-color: $primary-500;
        z-index: 1;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transform: scale(0) translate(-50%, -50%);
        background-color: white;
        z-index: 2;
      }

      &:checked {
        border: 1px solid $primary-600;
      }

      &:focus {
        border: 1px solid $gray-500;
        outline: 1px solid $primary-500;
        outline-offset: 2px;

        &::before {
          transform: scale(1);
        }

        &::after {
          transform: scale(1) translate(-50%, -50%);
        }
      }
    }

    input[type="radio"]:checked::before {
      transform: scale(1);
    }

    input[type="radio"]:checked::after {
      transform: scale(1) translate(-50%, -50%);
    }

  }

  #personal-info-entry {
    .username {
      margin-bottom: $spacing-medium;

      &-text {
        font-size: $font-size-lg;
      }
    }

    .flow-page-block__caption {
      margin-bottom: $spacing-medium;
    }

    input {
      font-size: $font-size-lg;
    }
  }

  #email-address-entry {
    .username-row {
      margin-bottom: $spacing-medium;
    }
  }
}