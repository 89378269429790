ul.document-list {
  padding: 0;

  li {

    &:last-of-type {
      border-bottom: 0;
    }

    &.show-border {
      border-bottom: 1.5px solid $sky-grey;
      border-top: 1.5px solid $sky-grey;
    }
  }
}