.component-library {
  .component-block {
    border: 1px solid $beluga-white;
    padding: 15px;
    margin: 15px 0 15px;
  }

  //This eventually can be moved to global (app class level) for all scroll-bars

  ::-webkit-scrollbar {
    width: 3px;
    height: 6px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px $ice-blue;
    padding: 0 3px 0 3px;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 8px;
    -webkit-box-shadow: inset 0 0 5px $ice-blue;
  }
}
