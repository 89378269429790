.action-row {

  &__backward {

    .mobile-spacing {
      margin: 0;

      @media (max-width: $screen-sm-min) {
        margin-bottom: 20px;
        float: none;
        width: 100%;
        text-align: center;

      }
    }
  }
}