.cash-pickup-wizard {
  .btn-lg {
    padding: 10px 50px;
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  ul li {
    position: relative;
    padding-bottom: 40px;
  }

  //Handles vertical line:
  ul li .digit-icon:before {
    content: "";
    position: absolute;
    border-left: 2px solid $blue;
    left: 32px;
    bottom: 0;
    z-index: -1;
    height: 100%;
  }

  ul li:last-child .digit-icon:before {
    border: 0;
  }

  ul li:last-child span:before {
    content: none;
  }

  ul li:last-child {
    padding-bottom: 0;
  }

  ul li:first-child {
    padding-top: 0;
  }

  .wizard-section-wrapper {
    //border-left: 2px solid $blue;
    margin: 0 30px;
    padding: 0 30px;
    z-index: -1;
  }

  .section-label {
    font-size: 24px;
  }

  .wizard-full-section-wrapper {
    margin-top: -15px;

    .h3 {
      padding-top: 0;
      margin-top: 5px;
      color: $blue;
    }
  }

  .pencil-image {
    margin-bottom: 3px;
  }

  @media (max-width: $screen-xs-min) {
    .wizard-full-section-wrapper {
      .h3 {
        font-size: 1.6em; //browser fallback
        font-size: 5vw;
      }
    }

    .btn-lg {
      padding: 10px 30px;
      line-height: 1;
    }
  }

  ul li:last-of-type .wizard-full-section-wrapper {
    //height: 0;
    border: 0;
  }

  .digit-icon {
    color: $blue;
    background: $white;
    border: 2px solid $blue;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 50%;
    padding: 3px;
    margin: 5px;
    font-size: 15px;
    text-align: center;
    line-height: 25px;
    width: 35px;
    float: left;
    margin-top: 0em;
    margin-left: 1em;
  }

  .locations-row {
    margin-bottom: 5px;
  }

  .pencil {
    font-size: 1.5em;
    margin: 0 5px;

    &:hover {
      text-decoration: none;
    }
  }

  &.success {
    .digit-icon {
      margin-top: 20px;
      float: none;
      padding: 5px 8px;
    }
  }

  .data-label {
    color: $blue;
    font-weight: bold;
    float: left;
  }

  .form-adjust-top {
    margin-top: 25px;

    label {
      padding-top: 5px;
    }
  }

  .logos {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .tfast-adjust {
      padding-bottom: 1vw;
    }
  }

  .blue-plus {
    color: $blue;
    font-size: 5vw;
  }

  .button-row {
    margin: 20px 0;
  }

  .summary {
    label.right {
      text-align: right;
    }
  }

  .rate-currencies {
    color: $blue;
    font-size: 0.9em;
  }

  .cash-pickup-alerts {
    padding-bottom: 30px;
    margin-top: -15px;
    padding-left: 15px;
  }

  .alert-text {
    color: $red;
  }

  .cp-disclosures {
    padding: 20px 10% 0 10%;
    text-align: center;
    font-size: 12px;
    color: $marengo-grey;
  }

  .cp-disclosures-success {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 12px;
    color: $marengo-grey;
  }
}