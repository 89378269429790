.facecheck-carousel {
  $indicator-size: 16px;
  $indicator-spacing: 14px;

  max-width: 588px;
  margin: 0 auto;

  .carousel {
    padding-bottom: $spacing-large;
  }

  .carousel-control {
    display: flex;
    opacity: 1;
    align-items: center;
    bottom: $spacing-medium;

    // placing the arrows outside of the carousel
    // area to not overlap with content image
    &.left {
      justify-content: flex-start;
      background: transparent;
      @media (min-width: $screen-md-min) {
        background-image: linear-gradient(
          to right,
          rgba($iceberg-white, 1) 0,
          rgba($iceberg-white, 0) 100%
        );
      }
    }

    &.right {
      justify-content: flex-end;
      background: transparent;
      @media (min-width: $screen-md-min) {
        background-image: linear-gradient(
          to left,
          rgba($iceberg-white, 1) 0,
          rgba($iceberg-white, 0) 100%
        );
      }
    }
  }

  .carousel-indicators {
    li {
      background: $white;
      border: 1px solid $sky-grey;
      width: $indicator-size;
      height: $indicator-size;
      margin: 0 $indicator-spacing;

      &.active {
        background-color: $interactive-blue;
        border-color: $interactive-blue;
      }
    }
  }

  .carousel-inner {
    margin-bottom: $spacing-small;

    img {
      max-width: 100%;
    }

    p {
      max-width: 406px;
      font-size: $font-size-xl;
      line-height: 140%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
