.security-questions {
  padding-bottom: $spacing-medium;
  border-bottom: 1.5px solid $sky-grey;
  margin-bottom: $spacing-medium;
}

.security-question {
  @media (min-width: $screen-xs-max) {
    display: flex;
    align-items: flex-end;
  }
}

.security-question-form {
  .form-group {
    margin-bottom: 0;
    label {
      font-size: 15px;
      color: $marengo-grey;
      font-weight: bold;
    }
  }
}
