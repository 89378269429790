.display-section-wrapper {

  .grid-cols-1 {
    grid-template-columns: 1fr;
  }

  .card-header__transparent {

    .card-header {
      background-color: transparent;
      border-bottom: 0;
    }
  }

  .card-header__padding {
    .card-header {
      padding: $spacing-small;
    }
  }

  .card-body {

    p,
    label {
      margin-bottom: 0;
    }
  }
}