@font-face {
  font-family: "BWIconFont";
  src: url("../fonts/Brightwell-Icons.eot"); /* IE9 Compat Modes */
  src: url("../fonts/Brightwell-Icons.woff") format("woff"),
    /* Pretty Modern Browsers */ url("../fonts/Brightwell-Icons.ttf")
      format("truetype"); /* Safari, Android, iOS */
}

@font-face {
  font-family: "dotsfont";
  src: url("../fonts/dotsfont.eot");
  src: url("../fonts/dotsfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/dotsfont.svg#font") format("svg"),
    url("../fonts/dotsfont.woff") format("woff"),
    url("../fonts/dotsfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
