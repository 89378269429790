.get-app-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
  padding: $spacing-default;

  @media (min-width: $screen-md-min) {
    display: none;
  }

  .flex-row {
    align-items: center;

    & > div:not(:first-child) {
      padding: 0 $spacing-tiny;
    }
  }

  .bw-icon {
    max-width: 3.5714em;
  }

  .btn-sm {
    min-width: 0;
    padding: $padding-btn/3 $padding-btn;
    border-radius: $border-radius * 4;
  }

  h4 {
    font-size: 1.0714em;
    margin-top: 0;
    color: $ocean-blue;
    font-weight: bold;
    margin-bottom: $spacing-tiny / 2;
  }

  p {
    font-size: 0.7857em;
    margin-bottom: 0;
  }
}
