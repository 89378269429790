.password-rule-block {
  float: right;

  @media (max-width: $screen-xs-max) {
    float: none;
    width: 100%;
    margin-bottom: 20px;
  }

  &__heading {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 175.7%;
    margin-bottom: $spacing-small;
  }

  &__ruleInfo {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: $spacing-small;
  }

  &__status-icon {
    position: relative;
    top: 3px;
    margin-right: 5px;
  }

  &+.password-input {
    &_input2 {
      float: left;

      @media (max-width: $screen-xs-max) {
        float: none;
      }
    }
  }
}

.password-input {
  position: relative;

  &_input1 {
    float: left;
    width: 50% !important;

    @media (max-width: $screen-xs-max) {
      float: none;
      width: 100% !important;
      margin-bottom: 0;
    }
  }

  &_input2 {
    width: 50% !important;

    @media (max-width: $screen-xs-max) {
      float: none;
      width: 100% !important;
    }
  }
}

.password-display-toggle {
  position: absolute;
  top: 0;
  background: none;
  border: none;
  bottom: 0;
  right: 0;
  padding: 10px 12px;
}

.notif-icon {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border: 2px solid rgba(19, 138, 67, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-holder {
    width: 90%;
    height: 28px;

    svg {
      width: 100%;
      height: 100%;

      .checkmark-icon {
        stroke-dasharray: 40;
        stroke-dashoffset: 40;
        fill: none;
        stroke: #138a43;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2.8;
        stroke-miterlimit: 4;
        opacity: 0.8;

        animation: checkmarkAnimation 450ms 300ms cubic-bezier(0.3, 0.06, 0.69, 0.45) forwards;
      }
    }
  }
}

@keyframes checkmarkAnimation {
  to {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}