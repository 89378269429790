//TAKEN FROM NAVIGATOR:
#LeftNavMenu {
  /*border-right: 1px solid #dadada;*/
  height: 100%;
}

#LeftNavMenu a {
  color: #878787;
  padding: 0;
  text-decoration: none;
}

.leftNavMenu-Selected {
  font-weight: 800;
}

#LeftNavMenu ul {
  color: #878787;
  display: block;
  font-size: 12px;
  list-style: none;
  margin-right: 10px;
  padding: 0;
  text-decoration: none;
}

#LeftNavMenu li {
  padding: 10px 0 0 0px;
}

#LeftNavMenu .subMenuItem {
  padding: 10px 0 0 30px;
}

.leftNavMenu-Title {
  border-bottom: 1px solid #dadada;
  font-size: 16px;
  margin: 10px 0 0 0;
  padding: 0 0 10px 0;
}
