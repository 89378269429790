#dashboard {
  @media (max-width: $screen-sm-min) {
    margin-top: $spacing-medium;
  }

  .alert {
    @media (max-width: $screen-sm-min) {
      margin-top: $spacing-small;
      margin-bottom: $spacing-small;
    }
  }

  .alert-danger {
    color: #f03;
    background-color: rgba(255, 0, 51, .25);
    border-color: #f03;
  }

  .dashboard-banner {
    background-color: $brightwell-blue;
    padding: $spacing-large 0;
    border-radius: $border-radius;
    margin-bottom: $spacing-large;

    @media (max-width: $screen-sm-min) {
      padding: $spacing-medium;
      margin-bottom: $spacing-medium;
    }

    .card-balance {
      color: white;
      font-weight: 800;
      font-size: 48px;
      padding-left: 0;
      margin: $spacing-small 0;
      line-height: 1.1;
    }

    h1,
    h2,
    p {
      color: white;
    }

    h1 {
      font-weight: 800;
      font-size: 48px;
      padding-left: 0;
    }

    h2 {
      font-weight: 400;
      font-size: 14px;
    }

    &.row {
      margin-right: 0;
      margin-left: 0;
    }

  }

  .panel {
    border: none;
    box-shadow: 0px 4px 15px 0px #00000040;
    margin-bottom: 0;

    .panel-heading {
      background-color: $off-white;
      color: $ocean-blue;

      h2 {
        color: $ocean-blue;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
      }
    }

    .list-group {
      padding: $spacing-small;

      .list-group-item {
        padding: $spacing-small 0;

        &:first-of-type {
          padding-top: 0;
        }

        &:last-of-type {
          padding-bottom: 0;
        }

        &.padding-bottom-small {
          padding-bottom: 15px;
        }

        &.no-border {
          border: 0;
        }
      }
    }

    li {
      color: $marengo-grey;

      a {
        text-decoration: none;
        color: $brightwell-blue;
      }
    }
  }

  .call-to-action {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $screen-xs-max) {
      flex-direction: column;
    }

    .action-link {

      &:first-of-type {
        margin-right: $spacing-small;

        @media (max-width: $screen-xs-max) {
          margin-bottom: $spacing-small;
          margin-right: 0;
        }
      }

      &:last-of-type {
        margin-left: $spacing-small;

        @media (max-width: $screen-xs-max) {
          margin-left: 0;
        }
      }
    }
  }

  .payment-summary {
    a {
      text-decoration: none;
    }

    .payments-body {
      color: $marengo-grey;

      p {
        color: $marengo-grey;
      }
    }
  }

  .payment-history-link {
    display: block;
    width: 100%;
    text-align: center;
    padding: $spacing-small 0;
    border-top: 1px solid #ddd;
  }
}

.exchange-rate-modal-body {

  .currency-picker {
    min-width: 90px;
  }

  .has-success .input-group-addon {
    background-color: #eee;
    color: #555;
  }

  p.fx-amount {
    height: 34px;
    padding: 6px 12px;
    background-color: #eee;
    color: #555;
    border-radius: 4px;
    border: 1px solid rgb(197, 208, 222);
  }
}